
$auw-shared: (icons: (this: does totally not work in this project, go: away));
$contact-box-offset: 100px;
$contact-box-position: right;

.contact-box {
  position: fixed;

  display: flex;
  align-items: flex-start;

  top: $contact-box-offset;

  #{$contact-box-position}: 0;
  z-index: 10000;

  &__close,
  &__figure {
    cursor: pointer;
  }

  &__figure {
    > * {
      pointer-events: none;
    }
  }

  &:not(.is-active) {
    z-index: -1;
  }
}
