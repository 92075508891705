%no-link {
  text-decoration: none;
  color: currentColor;
}

.extbase-debugger {
  overflow: visible;
}

.h2--underlined,
.hr-headline .header > * {
  border-bottom: 1px solid #F07300;
  padding-bottom: 4px;
}

.ce-bodytext {
  h1 + ul, .h1 + ul, h2 + ul, .h2 + ul, .h3 + ul, h3 + ul, .h4 + ul, h4 + ul, .h5 + ul, h5 + ul, .h6 + ul, h6 + ul {
    margin-top: 8px;
  }
}
