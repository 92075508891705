.ce-uploads {
  list-style-type: none;
  padding: 0;

  img {
    display: none;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    margin-right: 5px;
  }

  li {
    margin-bottom: 15px;

    > div {
      width: 100%;
    }
  }

  &-filesize:not(:empty) {
    @extend small;

    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  }

  &-fileName {
    max-width: 80%;

    display: inline-block;
    vertical-align: middle;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
      content: "";
      display: inline-block;
      padding: 1em 0 0 1em;
      background-image: url(~T3/auw_config/Resources/PubStatic/Images/download.svg);

      margin-right: 0.15em;
      vertical-align: middle;
      font-size: rem(24);
    }
  }

  &-description {
    padding-left: 2.7rem;
    display: block;
  }

  // hack filesize above description
  & > li > div {
    display: flex;
    flex-wrap: wrap;
    & > a {
      order: 1;
      flex-grow: 1;
    }
    & > .ce-uploads-description {
      order: 3;
      width: 100%;
    }
    & > .ce-uploads-filesize {
      order: 2;
    }
  }
}
