$tab-color: color("primary");
$tab-color-active: $tab-color;
$tab-color-hover: shade($color-white, 5%);
$tab-border-color: #CCC;
$tab-color-static: shade($color-white, 5%);

$tab-mode: "overflow"; // can be "fixed" or "overflow"

// ----
// Module base
// ----
.tabs {
  margin-bottom: 1.5em;
}

.tabs__nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 30em) {
    justify-content: center;
  }
}
// ----
// Styling types
// ----

// Tab Basic
.tabs__nav--basic {
  border-bottom: 1px solid $tab-border-color;
}

// Tab Basic: Link
.tabs__nav--basic .tabs__navAnchor {
  border-bottom: 1px solid transparent;
}

// Tab Basic: Active
.tabs__nav--basic .tabs__navAnchor--is-active {
  border-color: $tab-color;
}

// Tab Boxed
.tabs__nav--boxed {
  border-bottom: 1px solid $tab-border-color;
}

// Tab Boxed: Link
.tabs__nav--boxed .tabs__navAnchor {
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0;
  padding-left: 30px;
  padding-right: 30px;

  background: $tab-color-static;
  border-bottom-color: $tab-border-color;

  @include text-color($tab-color-static);

  &:not(.tabs__navAnchor--is-active):hover,
  &:not(.tabs__navAnchor--is-active):focus {
    background: $tab-color-hover;
    border-bottom-color: $tab-border-color;

    @include text-color($tab-color-hover);
  }
}

// Tab Boxed: Active
.tabs__nav--boxed .tabs__navAnchor--is-active {
  background: #fff;
  border-color: $tab-border-color;
  border-bottom-color: transparent;
}

// Tab Toggle
.tabs__nav--toggle .tabs__navItem:not(:first-child) {
  margin-left: -1px;
}

// Tab Toggle: Link
.tabs__nav--toggle .tabs__navAnchor {
  background: #f5f5f5;
  border: 1px solid $tab-border-color;
  padding: 10px 20px;

  &:not(.tabs__navAnchor--is-active):hover,
  &:not(.tabs__navAnchor--is-active):focus {
    background: $tab-color-hover;

    @include text-color($tab-color-hover);
  }
}

// Tab Toggle: Active
.tabs__nav--toggle .tabs__navAnchor--is-active {
  background: $tab-color-active;
  @include text-color($tab-color-active);
}

// ----
// Alignment
// ----
@media (min-width: 30em) {
  .tabs__nav--left {
    justify-content: flex-start;
  }

  .tabs__nav--right {
    justify-content: flex-end;
  }

  .tabs__nav--center {
    justify-content: center;
    text-align: center;
  }

  .tabs__nav--justify {
    justify-content: center;
    text-align: center;
  }

  .tabs__nav--justify .tabs__navItem {
    flex: 1 0 auto;
  }
}

// ----
// Tab button items
// ----
.tabs__navItem {
  @media (max-width: 30em) {
    text-align: center;
    width: 100%;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }
}

.tabs__navAnchor {
  @extend %no-link;

  outline: none;

  color: #666;
  display: block;
  margin-bottom: -1px;
  padding: 10px;
  text-decoration: none;
}

.tabs__navAnchor--is-active {
  color: $tab-color;
}

// ----
// Tab panels
// ----
.tabs__content {
  border: 1px solid $tab-border-color;
  border-top: 0;
  border-radius: 0 0 0.3em 0.3em;
  position: relative;
}

.tabs__panel {
  padding: 1em;
}

.tabs__nav--toggle ~ .tabs__content {
  border: 1px solid $tab-border-color;
  border-radius: 0.3em;
  margin-top: 1em;
}

.tabs__panel--is-disabled {
  display: none;
}

.tabs__panel--is-active {
  display: block;
}

@if ($tab-mode == "overflow") {
  .tabs {
    position: relative;

    .scroll-end::before,
    .scroll-start::after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      width: 60px;

      pointer-events: none;
    }

    .scroll-start::after {
      right: 0;
      background: linear-gradient(90deg, rgba(white, 0), #fff);
    }

    .scroll-end::before {
      left: 0;
      background: linear-gradient(-90deg, rgba(white, 0), #fff);
    }
  }

  .tabs__nav .tabs__navItem {
    flex: unset;
    min-width: 40%;
    @include media(">=desktop") {
      min-width: 200px;
    }
  }

  ul.tabs__nav.tabs__nav--boxed.nav-tabs {
    justify-content: flex-start;
    flex-flow: nowrap;

    overflow-y: hidden;
    overflow-x: auto;

    @include media(">=desktop") {
      &::-webkit-scrollbar {
        width: 11px;
        height: 11px;
        background: $tab-color-static;
        border-radius: 4px 4px 0 0;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid $tab-color-static;
        background: $tab-color;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
}
