$footer-background: #f3f3f4;
.footer {
  position: relative;
  padding: 40px 0;
  @include text-color($footer-background);
  background: $footer-background;
  border-top: 8px solid color("accent");

  p a[href] {
    color: $link-color;
  }

  .footer-navigation {
    ul {
      list-style: none;
      padding-left: 0;

      li {
        a {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .footer-about,
  .footer-navigation {
    header {
      h4 {
        border-bottom: 2px solid color("accent");
        padding-bottom: 5px;
        margin-bottom: 3px;
      }
    }
  }

  .footer-logo .image img {
    max-width: 250px;
  }

  @include media ("<=tablet") {
    .footer-logo .image img {
      max-width: 300px;
    }
  }
}


