
$auw-shared: (icons: (this: does totally not work in this project, go: away));
@import "config";
@import "photoswipe/src/css/main";

.gallery {
  %hidden {
    display: none;
  }

  img {
    width: 100%;
  }

  &__figure {
    &--hidden {
      @extend %hidden;
    }
  }
  &__thumbnail {
    &--hidden {
      @extend %hidden;
    }
  }
}
