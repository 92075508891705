.ce-gallery,
.ce-nowrap .ce-bodytext,
.ce-row,
.ce-textpic,
.ce-uploads div,
.ce-uploads li {
  overflow: hidden;
}

.ce-column {
  padding-left: 15px;
  padding-right: 15px;
}

.ce-headline- {
  $root: &;
  width: 100%;
  &center {
    @extend #{$root};
    @extend .text--center;
  }
  &right {
    @extend #{$root};
    @extend .text--right;
  }
  &left {
    @extend #{$root};
    @extend .text--left;
  }
}

.ce-center .ce-gallery {
  text-align: center;
  figcaption {
    margin-left: auto;
    margin-right: auto;
  }
}

// Intext (means floating image, top-margin reflects line-height)
.ce-intext.ce-right .ce-gallery {
  margin-left: $grid-gutter-half;
  margin-top: 8px;
  float: right;
}

.ce-intext.ce-left .ce-gallery {
  margin-right: $grid-gutter-half;
  margin-top: 8px;
  float: left;
}

.ce-intext.ce-nowrap .ce-gallery {
  margin-top: 0;
}

.ce-intext.ce-left ol,
.ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto;
}

.ce-intext .ce-column {
  margin-bottom: 10px !important;
}

// ausserhalb text rechts
.ce-right {
  .ce-gallery {
    float: right;
  }
  &.ce-above .ce-bodytext {
    clear: right;
  }
}

// Above and Below
.ce-above .ce-bodytext:not(:empty) {
  margin-top: 10px;
}

.ce-below .ce-bodytext:not(:empty) {
  margin-bottom: 10px;
}

// in full-width
.full-width .ce-above .ce-gallery {
  margin-bottom: 0;
}

// Margins nach bootstrap-mechanik
.ce-row {
  margin-left: -15px;
  margin-right: -15px;
}

.ce-column {
  position: relative;
  float: left;
}

// Sonderfall: Einspaltig (heisst: keine Galerie, sondern einzelnes Bild)
.ce-gallery[data-ce-columns="1"] {
  .ce-column {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
  .ce-row {
    margin-bottom: 0;
  }
}

// Breite Spaltigkeit 2-5, wer braucht schon mehr?
.ce-gallery[data-ce-columns="2"] .ce-column {
  width: 50%;
}

.ce-gallery[data-ce-columns="3"] .ce-column {
  width: 33.3333%;
}

.ce-gallery[data-ce-columns="4"] .ce-column {
  width: 25%;
}

.ce-gallery[data-ce-columns="5"] .ce-column {
  width: 20%;
}

// Sizing fix for IE 11
.ce-gallery .ce-column figure {
  display: block;
}

// Responsive, too... On Phones alles Einspaltig / Margins weg
@include media("<=tablet") {
  .ce-gallery[data-ce-columns] .ce-column {
    width: 100%;
    figure {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  div.ce-intext[class^="ce-"] .ce-gallery {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    float: none;
  }
}

@include media("<=phone") {
  .ce-gallery {
    width: 100%;
  }
}

// Bildunterschriften decoration
// Don't forget to add the JS for sizing to the appropriate JS-file AND the titles-in-galleries-function in !
.ce-gallery figcaption {
  margin-top: 5px;
  color: color("foreground", "muted");
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
}
