.form-course-info {
  max-width: 1155px;

  .contact .image img {
    border-radius: 100%;
  }

  .contact .image {
    margin-top: 5px;
  }

  &__content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 40px;

    background-color: #eee;
    padding: 50px;

    margin-bottom: 40px;

    border: 1px solid color("accent");

    @include media("<=tablet") {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      padding: 25px;
    }
  }

  &__headline {
    margin-bottom: 40px;
    text-align: center;

    @include media("<=tablet") {
      text-align:left;
    }

    p {
     margin-bottom: 0;
    }

    h2 {
      margin-top: 0;
      padding-bottom: 3px;
    }
 }



  &__subline {
    margin-bottom: 20px;
  }

  .form-course-info__items--compulsory,
  .form-course-info__items--module {
    margin-bottom: 3px;
  }

  &__items {
    .module {
      margin-bottom: 30px;

      width:80%;

      @include media("<=tablet") {
        width: 100%;
      }

      .module__info {
        background-color: #f7f7f7;
        border: 1px solid color("background", "dark");
      }

      .date,
      .location {
        padding: 10px 15px;
        margin: 0;
      }

      .location {
        @include media("<=phone") {
          padding-top: 0;
          display: block;
        }
      }

      .name {
        padding: 0 0 5px 0;
        margin: 0;
      }

      .date,
      .location {
        display: inline-block;
      }
    }
  }

  // STYLING FOR THE SEMINAR REGISTRATION
  .course {
    .form-course-info__items {
      padding: 10px 15px;

      background-color: #f1f1f1;
      border: 1px solid color("background", "dark");

      width: 80%;

      @include media("<=phone") {
        width: 100%;
      }

      .date,
      .location {
        display: inline-block;
        margin: 0;
      }

      .date {
        padding-right: 30px;
      }
    }
  }
}

// ANMELDEFORMULAR

// Who ever have to work with this, I am so sorry. PowerMail got me

.form-course-anmeldeformular {

  header {
    text-align: center;

    @include media("<=tablet") {
      text-align: left;
    }
  }

}




    .powermail_legend {
      display: table;
      float: left;
      font-weight:600;

      margin-bottom: 10px;
      border-bottom: 1px solid color("accent");
    }

    .powermail_fieldset_8,
    .powermail_fieldset_9,
    .powermail_fieldset_16,
    .powermail_fieldset_18,
    .powermail_fieldset_20,
    .powermail_fieldset_22,
    .powermail_fieldset_10,
    .powermail_fieldset_12,
    .powermail_fieldset_13,
    .powermail_fieldset_25 {
      background-color: #eee;
      padding: 25px !important;
      margin-bottom: 20px !important;
      // text-align: center;

      .powermail_fieldwrap {
        width: 45%;
        display: inline-block;

        margin-right: 20px;

        &.powermail_fieldwrap_hinweiszurbvl_mitgliedsnummer,
        &.powermail_fieldwrap_hinweiszurbvl_mitgliedsnummer_01,
        &.powermail_fieldwrap_hinweiszurbvl_mitgliedsnummer_02,
        &.powermail_fieldwrap_hinweiszurbvl_mitgliedsnummer_03,
        &.powermail_fieldwrap_hinweiszurbvl_mitgliedsnummer_04,
        &.powermail_fieldwrap_hinweiszurbvl_mitgliedsnummer_05 {
          display: block;
          font-size: 11px;
          margin-bottom: 15px;
        }
      }
    }

    .powermail_fieldset_8,
    .powermail_fieldset_9,
    .powermail_fieldset_16,
    .powermail_fieldset_18,
    .powermail_fieldset_20,
    .powermail_fieldset_22,
    .powermail_fieldset_10 {
      .powermail_fieldwrap_type_input:first-of-type {
        display: block;
      }
    }

    .powermail_fieldset_11 {
      background-color: #eee;
      padding: 25px !important;

      .powermail_fieldwrap {
        clear: left;
      }
    }

    .powermail_fieldset_12 {
      margin-top: 25px !important;

      .powermail_fieldwrap_firma_01,
      .powermail_fieldwrap_e_mailfuerrechnungsversand,
      .powermail_fieldwrap_weitererechnungsangaben,
      .powermail_fieldwrap_alternativerechnungsanschrift {
        display: block;
      }
    }

    .powermail_fieldwrap_rechnungsperpost,
    .powermail_fieldwrap_alternativerechnungsanschrift,
    .powermail_fieldwrap_rechnungsanschriftwiefirmadesteilnehmers {
      label.powermail_label {
        font-weight: 600;
      }
    }

    .powermail_fieldset_25 {
      margin-top: -20px !important;

      .powermail_fieldwrap_firma_03,
      .powermail_fieldwrap_e_mailfuerrechnungsversand_02,
      .powermail_fieldwrap_weitererechnungsangaben_01 {
        display: block;
      }
    }

    .powermail_fieldset_13 {
      .powermail_fieldwrap_e_mailfuerrechnungsversand_01 {
        display: block;
      }
    }

    .powermail_fieldset_14,
    .powermail_fieldset_17,
    .powermail_fieldset_19,
    .powermail_fieldset_21,
    .powermail_fieldset_23 {
      background-color: #eee;
      padding: 5px 25px !important;
      margin-top: -10px !important;
      margin-bottom: 20px !important;

      .powermail_label {
        font-weight: 600;
      }
    }

    .powermail_fieldset_8,
    .powermail_fieldset_9,
    .powermail_fieldset_16,
    .powermail_fieldset_18,
    .powermail_fieldset_20,
    .powermail_fieldset_22,
    .powermail_fieldset_14,
    .powermail_fieldset_17,
    .powermail_fieldset_19,
    .powermail_fieldset_21,
    .powermail_fieldset_23 {
      margin-bottom: 0 !important;
    }

    .powermail_fieldset_10 {
      margin-top: 25px !important;
    }

    .powermail_fieldset_23 {
      padding-bottom: 25px !important;
    }

    .powermail_fieldwrap_teilnahmebedinungungen,
    .powermail_fieldwrap_newsletter {
      label[for=powermail_field_teilnahmebedinungungen],
      label[for=powermail_field_newsletter] {
        font-weight: 600;
      }
    }

    .powermail_fieldwrap_absenden {
      margin-top: 20px;
    }

@include media("<=phone") {
  .powermail_fieldset_8,
  .powermail_fieldset_9,
  .powermail_fieldset_16,
  .powermail_fieldset_18,
  .powermail_fieldset_20,
  .powermail_fieldset_22,
  .powermail_fieldset_10,
  .powermail_fieldset_12,
  .powermail_fieldset_13,
  .powermail_fieldset_25 {
    background-color: #eee;
    padding: 15px !important;

    .powermail_fieldwrap {
      width: 100%;
      display: block;

      margin-right: 0px !important;
    }
  }
}

.powermail_fieldset_47,
.powermail_fieldset_49,
.powermail_fieldset_48 {
  background-color: #eee;
  padding: 25px !important;
}

.powermail_fieldset_47 {
  label {
    clear: both;
    display: block !important;
  }

  legend.powermail_legend {
    margin-bottom: 15px !important;
  }
}

.powermail_fieldset_48 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  legend.powermail_legend {
    margin-bottom: 10px;
  }

  .powermail_fieldwrap {
    display: block;
    clear: both;
  }

  .powermail_field_rechnungsperpost {
    display: block;
    clear: both;
  }

  .powermail_fieldwrap_e_mailfuerrechnungsversand {
    margin-top: 25px;
  }
}

.powermail_fieldset_49 {
  padding-top: 5px !important;

  input#powermail_field_vorname_02 {
    clear: both;
    display: block;
  }
}

// SEMINAR SPEZIFISCHES FORMULAR

.powermail_fieldset_24 {
  .powermail_fieldwrap:first-of-type {
    clear: left;
  }
}

.powermail_fieldset_50 {
  background-color: #eee;
  padding: 25px !important;

  .powermail_fieldwrap {
    clear: both;
  }
}
