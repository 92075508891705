/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// @access public
/// @type String
$asset-base-path: "../";

/// Document's base calculation size.
/// 10 is a suggested proper value.
/// @access public
/// @type String
$document-base-size: 10;

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;
