$spacings: (
  normal: 30px,
  medium: 45px,
  large: 75px
);

@each $name, $space in $spacings {
  .spacing__top--#{$name} {
    margin-top: $space;
  }
  .spacing__bottom--#{$name} {
    margin-bottom: $space;
  }
}
