.btn-filestyle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  border-left: none;
  margin-left: -2px;
}

form {
  [type="submit"] {
    margin-top: 0.5em;
  }
}

.form-group {
  label.control-label {
    @extend label.powermail_label;
  }

  .input {
    margin-bottom: 1rem;

    .inputs-list {
      margin-bottom: 1rem;
    }

    /*
    //date picker input
    input[type="input"] {
      @extend %object-date;
    }*/

    .error,
    .help-block {
      display: inline-block;
      max-width: 600px;
      br {
        display: none;
      }
    }
  }
}

// TODO: Consider removing. Selector not general enough.
.tx-felogin-pi1 {
  fieldset {
    margin: 0;
    padding: 0;
    border-width: 0;

    /*
    legend {
      display: none;
    }
    */

    label {
      @extend label.powermail_label;
    }

    & > div {
      margin-bottom: 1rem;
    }
  }
}
