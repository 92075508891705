$social-sharing-iconsize: 24px !default;
$social-sharing-animation-duration: 300ms;
$social-sharing-animation-function: ease;
$social-sharing-tooltip-background: $color-black;
$social-sharing-tooltip-arrow-size: 5px;
$social-sharing-tooltip-font-size: 1.6rem;

.social-sharing {
  $root: &;

  @include list-unstyled;

  display: inline-flex;

  &--spread {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;

    line-height: $social-sharing-iconsize;
    width: $social-sharing-iconsize;
    height: $social-sharing-iconsize;

    text-align: center;

    &:not(:last-child) {
      margin-right: 0.3em;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &__icon {
    @extend %no-link;
    font-size: $social-sharing-iconsize;
  }

  &__tooltip {
    display: none;

    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);

    padding: 0 0.8em;
    margin-bottom: $grid-gutter-half;

    border-radius: $border-radius-base;

    font-size: $social-sharing-tooltip-font-size;
    white-space: nowrap;
    line-height: 2;

    background: $social-sharing-tooltip-background;
    color: text-color($social-sharing-tooltip-background);

    animation-duration: $social-sharing-animation-duration;
    animation-timing-function: $social-sharing-animation-function;

    #{$root}__item:hover & {
      //($duration: 300ms, $function: ease, $fill: both)
      display: block;
    }

    &::after {
      content: "";
      position: absolute;

      top: 100%;
      left: 50%;
      margin-left: $social-sharing-tooltip-arrow-size * -1;

      border: $social-sharing-tooltip-arrow-size solid transparent;
      border-top-color: $social-sharing-tooltip-background;
    }
  }
}
