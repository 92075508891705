.course-detail-stage {
  .btn {
    @include media("<=phone") {
      width: 100%;
    }
  }

  .course-detail-stage__meta {
    .course-detail-stage__meta-sitetyp {
      color: color("primary", "base");
      font-weight: 600;
      margin-bottom: 4px;
    }
  }

  .text--lead {
    font-size: $font-size-base;
    color: $text-color--black;
  }
}
