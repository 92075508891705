.is-ie {
  .workshop {
    &__row {
      display: flex !important;
      justify-content: space-between;
    }

    &__date-text,
    &__price,
    &__sign-up,
    &__location,
    &__hotels,
    .contact {
      min-width: 30%;
    }

    &__sign-up {
      form {
        width: 100%;
      }

      .workshop__register {
        width: 100%;
      }
    }
  }

  .form-course-info__content {
    display: flex !important;
    justify-content: space-between;

    .form-course-info__container {
      min-width: 66%;
    }
  }

  .teaser--person {
    min-height: 1px;
  }

  .teaser--person {
    display: block !important;
  }

  .workshop-status--pending,
  .workshop-status--generic,
  .workshop-status--full {
    top: 13px;
  }

  .filter-panel .facet-list li a .hitcount {
    top: 0px;
  }

  .header__logo {
    margin-bottom: -55px;
  }

  .accortabs.accortabs--accordion .accortabs__title > .grow {
    flex: auto;
  }

  .powermail_fieldwrap {
    display: block !important;
  }

  .accortabs__title {
    &:after {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .is-ie {
    .accortabs__title:after {
      position: absolute;
      right: 15px;
    }

    .workshop {
      &__row {
        flex-direction: column;
      }

      &__date-text,
      &__price,
      &__sign-up,
      &__location,
      &__hotels,
      .contact {
        width: 100%;
      }
    }

    .form-course-info__content {
      flex-direction: column;

      .form-course-info__container {
        width: 100%;
      }
    }
  }
}
