.topic-stage {
  .headline {
    display: flex;
    align-items: center;

    margin-top: 20px;
    padding-bottom: 20px;

    border-bottom: 3px solid color("accent", "base");

    .icon {
      margin-right: 20px;
      color: color("accent");

      @include media ("<=phone") {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    @include media ("<=phone") {
      flex-direction: column;
      text-align: center;
    }
  }
}
