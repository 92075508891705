$contact-box-background: color("primary", "dark");
$contact-box-figure: $contact-box-background;

.contact-box {
  $root: &;

  position: fixed;
  display: flex;
  align-items: flex-start;
  right: 0;
  top: 100px;
  z-index: 99999;

  @include media("<=tablet") {
    left: 0;

    &__figure {
      display: none;
    }
  }

  &__figure {

    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px 0 0 8px;
    font-size: 24px;

    cursor: pointer;

    background: $contact-box-figure;
    color: text-color($contact-box-figure);

    z-index: -1;
    transition: transform ease 400ms;

    &.is-active {
      transform: translate3d(100%, 0, 0);
    }

    &-mobile {
      font-size: 24px;

      padding: 15px;
      line-height: 0;

      color: $contact-box-figure;

      > * {
        pointer-events: none;
      }
    }
  }

  &__body {
    padding: 15px;
    min-width: 380px;

    background: $contact-box-background;
    color: text-color($contact-box-background);

    @include media("<=tablet") {
      min-width: 0;
      width: 100%;
    }

    form {
      br {
        display: none;
      }

      [type="submit"] {
        display: block;
        color: $color-white;

        font-weight: 400;

        margin: $grid-gutter-half auto 0;

        @include media("<=desktop") {
          margin: $grid-gutter-half 0 0;
        }
      }
    }

    h5 {
      margin-top: 0;
      color: inherit;

      font-weight: 400;
    }

    p {
      font-size: rem(16);
      font-weight: 200;
      line-height: 1.85;

      a {
        color: inherit;
        font-weight: 500;
      }

      i {
        width: 22px;
        text-align: left;
      }
    }
  }

  &__button-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 24px;
    height: 24px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
