$pager_gray: #ddd;
$pager_size: 40px;

.auwSearch__paginationBottom {
  display: flex;
  justify-content: space-between;
}

.pagination {
  padding: 0;
  margin: $grid-gutter-width 0 0;
  clear: both;

  &--center {
    text-align: center;
  }

  ul.pagination__list {
    padding-left: 0;

    display: inline-flex;

    border: 1px solid $pager_gray;
    border-radius: 3px;
    overflow: hidden;
  }

  li.pagination__item {
    display: inline-block;
    min-width: $pager_size;
    min-height: $pager_size;
    text-align: center;
    line-height: $pager_size;
    position: relative;

    &:not(:last-of-type) a {
      border-right: 1px solid $pager_gray;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: color("accent");
      // border: $pager_gray 1px solid;
      &:active,
      &:focus,
      &:hover,
      &:visited {
        background-color: mix($pager_gray, $color-white);
        text-decoration: none;
      }
    }

    &.active {
      a {
        background-color: mix($pager_gray, $color-white);

        &:hover {
          text-decoration: none;
          cursor: not-allowed;
        }
      }
    }

    &.disabled {
      a {
        &:hover {
          text-decoration: none;
          cursor: not-allowed;
        }
      }
    }

    &.prev {
      a {
      }
    }

    &.next {
      a {
      }
    }
  }
}
