.powermail_field-select {
  @extend %input-select;
}

.extbase-debugger input[type="checkbox"] {
  left: 0 !important;
}

.parsley-error {
  @extend %input-error-outlined;
}

.parsley-errors-list.filled {
  @extend %input-error;
}

.powermail_submit {
  @extend .button--primary;
  @extend .button--squared;
}
$powermail-show-fieldset-frame: false !default;
@if $powermail-show-fieldset-frame != true {
  .powermail_fieldset {
    border: 0 none;
    padding: 0;
    margin: 0;
  }
}

.nolabel {
  > label {
    display: none !important;
  }
}

label.powermail_label {
  display: inline-block;
  margin-top: rem(10);
  margin-bottom: rem(5);
}
