$accordion-animation-function: ease;
$accordion-animation-duration: 200ms;

$accordion-caret-size: 5px;

$accordion-panel-height: 43px;
$accordion-panel-padding: $grid-gutter-half;
$accordion-panel-background: color("background");
$accordion-content-background: $color-white;
$accordion-border-width: 1px;
$accordion-border-color: #cccccc;

.accordion {
  $root: &;

  position: relative;
  list-style: none;

  width: 100%;

  padding: 0;
  margin: 0;

  border: $accordion-border-width solid $accordion-border-color;
  border-radius: $border-radius-base;
  overflow: hidden;

  &__body,
  &__heading {
    border-bottom: $accordion-border-width solid $accordion-border-color;
  }

  &__heading {
    background-image: url("~T3/auw_config/Resources/PubStatic/Images/caret.svg");

    // height: $accordion-panel-height;
    height: auto;

    line-height: $accordion-panel-height;
    padding: 0 28px 0 $accordion-panel-padding;

    font-size: 1.6rem;
    background: $accordion-panel-background;
    color: $color-black;
    font-weight: 400;

    background: #f3f3f4;

    &[aria-expanded="true"]::before {
      transform: rotateZ(0deg);
    }

    outline: none;

    &::before {
      position: absolute;
      right: $accordion-panel-padding;
      top: 0;

      font-size: $accordion-caret-size;

      transform: rotateZ(-90deg);
      transform-origin: center;
      transition: transform ease-in-out 300ms;
    }
  }

  &__body {
    padding: $accordion-panel-padding;
    background: $accordion-content-background;
  }

  &,
  &__item {
    will-change: height, transform;
    perspective: 90em;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }

  &__item {
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;

    width: 100%;
  }

  &.edge-visible,
  &__item,
  .accordion {
    transition: $accordion-animation-duration $accordion-animation-function all;
  }

  &.is-snapping,
  .accordion {
    transition: none !important;
  }

  > {
    #{$root}__item > {
      :first-child {
        cursor: pointer;
        margin: 0;
        user-select: none;
      }

      :last-child {
        overflow: hidden;
        transition: $accordion-animation-duration $accordion-animation-function
            height,
          $accordion-animation-duration step-start visibility;
      }
    }

    .is-closed {
      .accordion > .is-open > :last-child,
      > :last-child {
        transition-timing-function: $accordion-animation-function, step-end;
        visibility: hidden;
      }
    }
  }
}

/** Collapsible content */

#price-infos .accortabs--accordion {
  .accortabs__title {
    display: flex;
    justify-content: space-between !important;

    &.is-active::after {
      right: 0;
      position: relative !important;
    }
  }
}

#price-infos > .container {
  max-width: 1140px;
}

@media screen and (max-width: 1200px) {
  #price-infos > .container {
    max-width: 940px;
  }
}

@media screen and (max-width: 990px) {
  #price-infos > .container {
    max-width: 720px;
  }
}
