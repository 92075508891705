@import "~normalize.css/normalize.css";

:root,
html,
body {
  margin: 0;
  min-height: 100%;
  min-height: 100vh;
}

:root,
html {
  display: flex;
  justify-content: center;
  align-items: stretch;

  background-color: #f3f3f4;

  font-family: "Roboto", system-ui, sans-serif;
}
