#certificate-course {
  .certificate-course__header {
    display: flex;
    justify-content: space-between;

//    border-top: 3px solid color("accent");
//    border-bottom: 3px solid color("accent");

//    padding: 40px 0;

    @include media ("<=tablet") {
      flex-direction: column;

      .contact {
        margin-top: 20px;
      }
    }

    .contact {
      h4 {
        margin-bottom: 10px;
      }
    }

    .price {
      padding-left: 0;
      padding-right: 10px;

      h2 {
        border-bottom: 1px solid #F07300;
        padding-bottom: 3px;
      }

      @include media(">tablet") {
        padding-right: 10px;
      }
      .certificate-course__price-information {
        a {
          font-size: 14px;
          font-weight: normal;
          line-height: 1;
        }
      }
    }
  }

  .registration {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 11px 20px;
    margin: 40px 0;

    background-color: #f1f1f1;
    border: 1px solid color("background", "dark");

    @include media ("<=tablet") {
      flex-direction: column;
      align-items: flex-start;
    }

    .selection-overview {
      p {
        margin-bottom: 0;
      }
    }

    &--fixed-bottom {
      position: fixed;

      bottom: 0;
      left: 0;

      width: 100%;
      z-index: 99999;

      border-top: 1px solid color("accent");

      margin-bottom: 0;

      &:not(.is-visible) {
        display: none;
      }

      .selection-overview {
        &:not(.is-visible) {
          display: none;
        }
      }

      .registration__footer {
        display: flex;
        align-items: center;

        width: 100%;

        i {
          cursor: pointer;
        }

        > form {
          flex: 1;
        }
      }
    }
  }

  .modules {
    .module {
      margin-top: 40px;

      &__headline {
        margin-bottom: 5px;
      }
    }
  }

  .compulsory-modules {
    .compulsory-module {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }

  .certificate-course__accordion {
    .accortabs--accordion {
      .accortabs__title:first-of-type {
        margin-top: 5px;
      }

      .accortabs__section {
        .workshop {
          &__row {
            grid-template-columns: 1fr 1fr;

            @include media ("<=tablet") {
              grid-template-columns: 1fr;
            }

            .workshop__select-date {
              grid-column-end: -1;

              @include media ("<=tablet") {
                margin-top: 10px;
              }

              button {
                width: 100%;
              }

              .select-date--infoInNewWindow {
                margin-top: -10px;

                a {
                  font-size: 14px;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}

// CERTIFICATE COURSE STATUS

#certificate-course {
  .registration {

    // Setting the registration sections
    &.registration__valid {
      border: 1px solid color-pick("success", $state-colors);
      background-color: color-pick("success-light", $state-colors);
    }

    &.registration__invalid {
      border: 1px solid color-pick("danger", $state-colors);
      background-color: color-pick("danger-light", $state-colors);
    }

    // Setting the selection checkmarks for the compulsory
    .selected-compulsory-modules {
      .registration__selection-compulsory--checked {
        display: none;
        color: color-pick("success", $state-colors);

        .fa {
          text-shadow: 0 0 1px white;
        }
      }

      .registration__selection-compulsory--invalid {
        display: none;
        color: color-pick("danger", $state-colors);

        .fa {
          text-shadow: 0 0 1px white;
        }
      }

      &.is-valid .registration__selection-compulsory--checked {
        display: inline-block;
      }

      &.is-invalid .registration__selection-compulsory--invalid {
        display: inline-block;
      }
    }

    // Setting the selection checkmarks for the modules
    .selected-modules {
      .registration__selection-module--checked {
        display: none;
        color: color-pick("success", $state-colors);

        .fa {
          text-shadow: 0 0 1px white;
        }
      }

      .registration__selection-module--invalid {
        display: none;
        color: color-pick("danger", $state-colors);

        .fa {
          text-shadow: 0 0 1px white;
        }
      }

      &.is-valid .registration__selection-module--checked {
        display: inline-block;
      }

      &.is-invalid .registration__selection-module--invalid {
        display: inline-block;
      }
    }

    // Setting input state
    #registration-form {
      .certificate-course__register.button--invalid {
        border: 1px solid color-pick("danger", $state-colors);
        background-color: color-pick("danger-lighter", $state-colors);

        opacity: 1;

        color: color-pick("danger", $state-colors);

        font-weight: bold;

        &:hover {
          opacity: 1;

          background-color: color-pick("danger-lighter", $state-colors);
          border: 1px solid color-pick("danger", $state-colors);
        }
      }
    }

    .certificate-course__register:disabled {
      opacity: 0.3;

      &:hover {
        background-color: color("accent");
        border: 1px solid color("accent");
      }
    }
  }

  // Setting green checkmarks to the accortabs title
  .accortabs {
    .accortabs__title {
      .accortabs__title--meta {
        .accortabs__title--checked {
          display: none;
          color: color-pick("success", $state-colors);
          margin-right: 6px !important;

          @include media ("<=tablet") {
            margin-bottom: -21px;
            position: absolute;
          }
        }
      }

      // when selected
      &.is-selected .accortabs__title--meta .accortabs__title--checked {
        display: inline-block;
      }

      &.is-selected {
        border: 1px solid color-pick("success", $state-colors);
      }

      @include media ('<=tablet') {
        &.is-selected .accortabs__title--meta .date-string,
        &.is-selected .accortabs__title--meta .city {
          margin-left: 20px;
        }
      }
    }
  }
}

#course-dates {
  .accortabs__title--checked {
    display: none;
  }
}


.workshop__row--contact {
  .contact {
    h4 {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
