$search-title-color: color('primary') !default;
$search-subtitle-color: gray !default;
$search-image-maxwidth: 85px;

.search-tabs {
  margin-top: 15px;
  display: flex;

  box-shadow: inset 0 -3px 0 mix($search-subtitle-color, $color-white);

  > a {
    flex: 1;
    border-radius: 0;
    border: none;

    &.is-active {
      box-shadow: inset 0 -3px 0 color('primary');
    }
  }
}

.search-result {
  $root: &;

  &--person {
    #{$root}__details > img {
      border-radius: 1000px;
    }
  }

  &__details > img {
    float: left;

    margin-right: 15px;
    margin-bottom: 5px;

    max-width: $search-image-maxwidth;
    height: auto;
  }

  &__nothing-found {
    margin-top: 30px;
  }
}

.search {
  $root: &;

  display: block;
  position: relative;

  &__title {
    font-weight: 100;
    color: $search-title-color;
  }

  &__subhead {
    color: $search-subtitle-color;
    font-weight: 300;
  }
}

.searchbox__send-button {
  font-weight: 800 !important;
  margin-top: -1px !important;
  color: color("accent", "base") !important;

  &:before {
    font-size: 1.9rem !important;
  }
}

// in-content searchbox
.auw-ce .searchbox {
  position: relative;

  &__send-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    margin: 0;
    padding: 0;
    background: none;

    &:hover {
      opacity: 0.8;
    }

    &::before {
      position: relative;
      top: 2px;
    }
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 45px;
    width: 20px;
    height: 45px;
    margin: 0;
    padding: 0;
    background: none;

    &:hover {
      opacity: 0.8;
    }

    &::before {
      position: relative;
      top: 1px;
    }
  }

  &__field {
    width: 100%;

    input {
      padding-right: 70px;
      max-width: none;

      &::placeholder {
        font-size: 16px;
        color: #363636;
        opacity: 1;
      }
    }

    label {
      display: none;
    }
  }
}
