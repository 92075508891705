$cookie-banner-background: mix($body-bg, color("foreground", "dark"), 12%);
.cookie-banner {
  position: fixed;
  z-index: 1000000;

  left: 0;
  right: 0;
  top: 0;

  background: $cookie-banner-background;
  color: text-color($cookie-banner-background);

  display: none;

  &.is-active {
    display: block;
  }

  &__container {
    padding: 10px 0 7px 0 !important;
  }

  @include media("<=tablet") {
    top: initial;
    top: unset;
    bottom: 0;
    position: fixed !important;
    padding: 10px !important;

    &__btn-agree {
      display: block;
      width: 100%;
    }
  }

  &__title {
    margin-top: 0;
  }

  &__body {
    max-width: 70rem;
    font-size: 1.4rem;
    margin-bottom: 1em;
    color: shade(text-color($cookie-banner-background), 16%);
  }
}
