$slider-bullet-offset: $grid-gutter-half;
$slider-bullet-space-between: 4px;
$slider-bullet-size: 2px;
$slider-bullet-background: $color-white;
$slider-bullet-opacity: 0.4;

$slider-bullet-position: bottom;
$slider-bullet-has-counter: false;

$slider-arrow-size: 24px;
$slider-arrow-offset: 50%;

$slider-arrow-colors: (
  $color-white,
  $color-black,
  ($color-white, rgba($color-black, 0.3))
);
$slider-block-text-color: $color-black;

$slider-block-background: linear-gradient(
  to top,
  rgba($color-black, 0.8) 0%,
  rgba($color-black, 0.075) 85%,
  rgba($color-black, 0) 100%
);
$slider-block-backgroud-height: 75%;
.is-ie {
  .slide {
    &__image img,
    img {
      height: 100%;
    }
  }
}
.slide {
  position: relative;
  $root: &;

  // maximize images to full size, no matter what
  &__image img,
  img {
    width: 100%;
    height: auto;
  }

  // info block
  &__block {
    width: auto !important;
    height: auto !important;
    min-height: 0 !important;

    padding: 15px 35px !important;

    background-color: rgba(255, 255, 255, 0.7) !important;

    left: 65px !important;
    right: 65px;
    bottom: 65px !important;

    color: $slider-block-text-color;

    // mobile
    @include media("<=desktop") {
      position: relative !important;
      padding: $grid-gutter-half !important;
      min-height: 0 !important;

      &::before,
      &:hover::before {
        background-color: transparent !important;
      }

      a:not(.tag),
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $text-color !important;
      }

      &::after {
        display: none;
      }
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }

  &__title {
    @extend .h1;
    @extend .card__title;

    margin-bottom: 0;

    @include media("<=phone") {
      font-size: $font-size-h2-mobile;
    }
  }

  &__text {
    margin: 0 0 15px;
    margin-top: 0 !important;
  }

  &__text {
    word-wrap: break-word;
  }

  &__layout {
    &--text {
      //@extend .card;
      @extend .card--minimal;
      @extend .card--backdrop;
      margin: 0;

      #{$root} {
        &__block {
          @extend .card__body;
          padding: 0 120px 40px 60px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--right {
      text-align: right;

      .slide__block {
        left: unset !important;
        right: 65px;
      }
    }
  }
}

.slider {
  //mobile
  @include media("<=desktop") {
    background-color: transparent;

    .slide__block {
      .slider__button {
        a {
          color: white !important;
        }
      }
    }
  }

  // internal, used for $slider-bullet-position
  $slider-bullet-position-map: ((top, bottom), (left, right)),
    ((left, right), (top, bottom));

  %inherit {
    button {
      background: currentColor;
    }
  }

  // Light slider image
  &--light {
    @extend %inherit;
    color: nth($slider-arrow-colors, 1);
  }

  // Dark slider image
  &--dark {
    @extend %inherit;
    color: nth($slider-arrow-colors, 2);
  }

  // Complex slider image
  &--complex {
    @extend %inherit;
    color: nth(nth($slider-arrow-colors, 3), 1);
    background-color: nth(nth($slider-arrow-colors, 3), 2);
  }

  // dot's styling
  &__dots {
    position: absolute;
    padding: $slider-bullet-offset;

    list-style: none;

    z-index: 5;
    display: flex;

    justify-content: center;
    align-items: center;
    @if (contains((left, right), $slider-bullet-position)) {
      flex-direction: column;
    } @else {
      flex-direction: row;
    }

    @include media('<=tablet') {
      top: 0;
      bottom: auto;
    }

    // bullet positioning
    @each $i in $slider-bullet-position-map {
      @if (contains(nth($i, 1), $slider-bullet-position)) {
        #{$slider-bullet-position}: 0;
        @each $prop in nth($i, 2) {
          #{$prop}: 0;
        }
      }
    }

    li {
      line-height: 0;

      &.slick-active {
        button {
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-right: $slider-bullet-space-between;
      }
    }

    button {
      border: none;
      appearance: none;

      cursor: pointer;

      display: inline-block;
      width: 0px !important;
      height: 12px !important;
      padding: 0 0 0.15em 12px;

      min-height: $slider-bullet-size !important;
      min-width: $slider-bullet-size !important;

      opacity: $slider-bullet-opacity;

      background: $slider-bullet-background;

      border-radius: 100%;

      &:focus {
        outline: none;
      }
      @if ($slider-bullet-has-counter == false) {
        font-size: 0;
      }
    }
  }

  &__arrow {
    position: absolute;
    padding: 16px;
    z-index: 10;
    font-size: $slider-arrow-size;

    top: $slider-arrow-offset;

    // mobile... anyone better solution?
    @include media("<=desktop") {
      top: 53px;
      transform: translate3d(0, 0, 0) !important;
    }

    margin-top: $slider-arrow-size * -0.5;

    transition: color ease 300ms;

    &--next {
      right: 0;
      @include icon("angle-right");
    }

    &--prev {
      left: 0;
      @include icon("angle-left");
    }
  }
}

.slider__arrow {
  color: color("foreground", "dark") !important;
}

.slide__block {
  color: color("foreground", "dark") !important;

  .slide__title, .slide__text, .slide__title a {
    color: color("foreground", "dark") !important;
  }
}

.slide__layout--text p:hover {
  color: color("foreground", "dark") !important;
}

.slider--complex {
  background-color: unset !important;
}

@media screen and (max-width: 992px) {
  .slide {
    &__layout {
      .slide__block {
        left: 0 !important;
        bottom: 0 !important;

        width: 100% !important;
        height: 100% !important;

        padding: 15px !important;

        background-color: #fff !important;
      }
      &--right {
        .slide__block {
          right: 0 !important;
          left: 0 !important;
        }
      }
    }
  }
}
