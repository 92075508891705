@mixin a-box-shadow($shadow-type, $shadow-collection: $shadows) {
  box-shadow: map-get($shadow-collection, $shadow-type);
}

@mixin border-radius($radi-type, $radi-collection: $radiis) {
  border-radius: map-get($radi-collection, $radi-type);
}

@mixin state($state, $revert: false) {
  $selector: unquote(".is-#{$state}");

  @if $revert {
    &:not(#{$selector}) {
      @content;
    }
  } @else {
    &#{$selector} {
      @content;
    }
  }
}
