$search-result-title-color: color('primary') !default;
$search-result-subtitle-color: gray !default;
$search-result-border-color: gray !default;

.search-result {
  $root: &;

  display: block;
  position: relative;

  padding-top: $grid-gutter-half;
  margin-bottom: $grid-gutter-width;

  &:not(:first-child) {
    border-top: 1px solid $search-result-border-color;
  }

  &__title {
    @extend .h3;
    margin-top: 0;

    font-weight: 100;
    color: $search-result-title-color;
  }

  &__tag {
    @extend .tag;
  }

  &__matched-content {
    font-weight: normal;

    mark {
      background: none;
      font-weight: bold;
    }
  }

  .details {
    flex-grow: 1;
  }

  &__next-date {
    // border: 1px solid red;
    font-weight: normal;
    font-size: 14px;
    padding: 0 0 0 30px;
    width: 160px;
    flex-shrink: 0;
    @include media ("<=phone") {
      padding: 10px 0 0 0;
    }
  }
}
