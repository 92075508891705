
$auw-shared: (icons: (this: does totally not work in this project, go: away));
/*!
                       _                   _              _
                      | |                 | |            (_)
             __ _ _ __| |_ _   _ _ __   __| |_      _____ _ ___  ___
            / _` | '__| __| | | | '_ \ / _` \ \ /\ / / _ \ / __|/ _ \,
           | (_| | |  | |_| |_| | | | | (_| |\ V  V /  __/ \__ \  __/
           \__,_|_|   \__|\__,_|_| |_|\__,_| \_/\_/ \___|_|___/\___|
                     Kreativagentur für die digitale Zeit


 ┌────────────────────────────────────────────────────────────────────────────┐
 │ Copyright (C) 2017 artundweise GmbH                                        │
 │                                                                            │
 │ Artundweise GmbH uses third-party libraries or other resources that may    │
 │ be distributed under different licensees.                                  │
 │                                                                            │
 │ In the event that we accidentally failed to contain a required notice,     │
 │ please bring it to our attention through any of the ways detailed here:    │
 │                                                                            │
 │ >> artundweise.de/kontakt                                                  │
 │ >> we@artundweise.de                                                       │
 └────────────────────────────────────────────────────────────────────────────┘
 */

//@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700,800");
@import url('/typo3conf/ext/auw_config/Resources/PubStatic/Fonts/fonts-googleapi-opensans-300-400-400i-700-800.css');

////
/// Remember, write BEM! Like so: .BLOCK{__ELEMENT[--MODIFIER]}
/// Read more: https://css-tricks.com/bem-101/
////
// Visual setup like: http://bradfrost.com/wp-content/uploads/2013/06/atomic-design-process-700x525.png
// architecture is like: http://technotif.com/wp-content/uploads/2015/04/Manage-Large-CSS-Projects-With-ITCSS.jpg
/**
  Cmpiled file is structured like:
  1. Settings: global variables, such as brand colours, configs etc
  2. Tools: mixins and helper functions
  3. Generic: ground zero styles, reset styles such as normalize.css
  4. Elements: a.k.a. Base, unclassed html elements, e.g. headings, lists, tables, this is the last layer that styles tag selectors
  5. Objects:  no cosmetic design patterns. Class only styles from here onwards. Classes here are loosely named and I start doing OOCSS here (e.g. .button, .list-item, grid systems etc)
  6. Components: rich design UI styling. Class names here are more specific. This layer usually has the biggest volume
  7. Themes: if applicable, thematic styles are placed here (e.g. seasonal Christmas theme)
  8. Trumps: utilities, helpers and overrides. !important is often used.


Settings: variables like colors and font sizes
Generic: reset styling, box-sizing
Elements: form elements, list styling
Objects: text input field, buttons, navigation items
Components: cosmetic treatment specifically for branding, search field and header navigation.

*/

// Settings — Variables
// ATTETION: Variables declared inside priority/ are not allowed to be used, only declared.

@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: none;
  outline-offset: 0;
}

@import "settings/priority/*";
// Tools — Functions/Mixins
@import "tools/index";
// These settings are allowed to cross-communicate.
// You can also override Variable here.
@import "settings/*";
@import "settings";
@import "bootstrap";
// Generic — 3rd Party imports
@import "generic/*";
// Base — Bare Elements
@import "base/*";
// Objects — Layout classes
@import "objects/*";
// Components — UI Classes
@import "components/*";
// Trumps — "Other", IDs, !important
@import "trumps/*";
