$facets-height: 253px;
$facets-height-mobile: 150px;

.panel-heading {
  display: flex;

  @include media ("<=tablet") {
    flex-direction: column;
  }

  .facets-select__wrapper {
    order: 1;

    min-width: 242.5px;

    @include media ("<=tablet") {
      width: 100%;
    }
  }

  .facceted__list-meta {
    order: 2;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    margin-left: 15px;

    @include media (">=tablet") {
      width: 862.5px;
    }

    @media screen and (max-width: 1200px) {
      width: 713.5px;
    }

    @include media ("<=tablet") {
      width: 100% !important;
      position: relative;
      order: 1;

      margin-left: 15px;
      padding-right: 15px;
    }

    .facceted__list-count {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .facetted-list__sorting--wrapper {
      width: 33.3%;

      @include media ("<=phone") {
        width: 60%;
      }
    }
  }

  .facets-select {
    border: 1px solid color("background", "dark");

    padding: 10px;

    .panel-title {
      color: $gray-darker;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 5px;
    }
    .list-group {
      a {
        display: block;

        font-weight: normal;
        color: $color-black;

        font-size: 14px;

        margin-top: 2px;

        &::before {
          content: "\F14A";
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          color: color-pick("success", $state-colors);
        }

        &:hover {
          text-decoration: none;
          color: color("accent");

          &::before {
            content: "\f146";
            text-decoration: none;

            color: color-pick("danger", $state-colors);
          }
        }
      }
    }

    .facets__reset {
      margin-top: 5px;
      .btn-reset {
        font-weight: normal;
        color: $color-black;
        font-size: 14px;
        margin-top: 5px;
        font-family: $font-family-sans-serif;

        .fa-minus-square {
          font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;

          &:hover {
            color: color('accent');
          }
          &::before {
            color: color-pick("danger", $state-colors);
            font-family: FontAwesome;
          }
        }
      }
    }
  }
}

.filter-panel {
  border: 1px solid color("background", "dark");

  padding: 10px;

  margin-top: -1px;

  .panel-headline {
    h5 {
      color: $gray-darker;
      font-weight: normal;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .panel-headline:not(:first-of-type) {
    margin-top: 30px;
  }

  .facet-list {
    list-style: none;
    padding-left: 0;
    // max-height: $facets-height;

    @include media('<=phone') {
      overflow-y: auto;
      max-height: $facets-height-mobile
    }

    // scrollbar styling, Webkit only
    &::-webkit-scrollbar {
      width: 7px;
      background-color: #d4d9db;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6a6c6d;
    }

    li {
      position: relative;
      margin-right: 4px;

      a {
        display: flex;
        align-items: baseline;

        font-weight: normal;
        color: $color-black;

        font-size: 14px;

        margin-top: 2px;

        @include media('<phone') {
          // Give the element more space for touch events
          padding-top: 3px;
          padding-bottom: 3px;
        }

        .hitcount {
          position: absolute;
          right: 0;

          // align-self: center;

          background-color: color("background", "dark");
          color: color("blue-gray");

          border-radius: 20px;
          font-size: 12px;

          padding: 0px 10px;
          font-weight: normal;

          align-self: flex-start;
          margin-top: 3px;
        }

        &:hover {
          text-decoration: none;

          color: color("accent")
        }

        &::before {
          content: "\f096";
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          margin-right: 5px;
        }
      }
    }
  }
}

#facettedList {
  @include media ("<=phone") {
    overflow: hidden;
  }

  .facettedList__headline {
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid color("accent");
  }

  @media screen and (max-width: 1199px) and (min-width: 992px) {
    .facet-name {
      width: calc(100% - 50px);
      hyphens: auto;
    }
  }

  @include media("<=phone") {
    .facet-name {
      width: calc(100% - 48px);
    }
  }

  .auwSearch__resultList {

    @include media ("<=tablet") {
      margin-top: 10px;
    }

    &--wrapper {
      margin-left: 0;
      margin-right: 0;
    }

    &--container {
      border: 1px solid color("background", "dark");
      border-left: 7px solid color("background", "dark");

      padding-left: 0;
      padding-right: 0;

      .search-result {
        margin-bottom: 0;
        padding: 0;
        border: 0;

        .search-result__link {
          display: flex;

          flex-direction: row;
          align-items: baseline;
          padding: 15px;
          justify-content: space-between;

          @include media ("<=phone") {
            flex-direction: column;
          }

          .type {
            font-size: 14px;
            min-width: 140px;
            margin-right: 30px;

            &.seminar {
              color: color("blue-gray");
            }
          }

          .details {
            .search-result__title {
              font-size: 16px;
              font-weight: bold;
              color: $color-black;
            }

            .search-result__teaser-text {
              font-size: 14px;
              font-weight: normal;
            }
          }

          &:hover {
            .details {
              .search-result__title {
                color: color("accent");
              }

              .search-result__teaser-text {
                color: black;
              }
            }

            .type {
              color: color("accent");
            }
          }
        }
      }

      .search-result:nth-child(even) {
        background-color: $gray;
      }
    }
  }
}

/* PAGING */

#facettedList {
  .pagination {
    .auwSearch__paginationTop {
      .auwSearch__paginationWrap {
        .pagination__list {
          overflow-x: auto;
          max-width: 100%;

          // scrollbar styling, Webkit only
          &::-webkit-scrollbar {
            height: 4px;
            background-color: #d4d9db;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #6a6c6d;
            border-radius: 4px;
          }

          .pagination__item {
            a {
              font-weight: normal;
              font-size: $paging-font-size;
            }

            &.active {
              a {
                color: color("blue-gray");
              }
            }
          }
        }
      }

      .auwSearch__paginationBottom {
        align-items: center;
        flex-direction: column;

        .auwSearch__resultsCounter {
          font-size: $paging-font-size-small;
          color: $gray-darker;
        }

        .auwSearch__resultsPerPage {
          font-size: $paging-font-size-small;
          margin-top: 10px;

          .selected {
            color: color("accent", "dark");
            text-decoration: underline;
          }
        }
      }
    }
  }
}


