
$auw-shared: (icons: (this: does totally not work in this project, go: away));
@import "config";

.cookieBanner {
  position: fixed;
  z-index: 999999999;
  @if ($cookie-banner-position == "bottom") {
    left: 0;
    right: 0;
    bottom: 0;
  }
  @else {
    left: 0;
    right: 0;
    top: 0;
  }

  &:not(#{unquote($cookie-banner-active-class)}) {
    display: none;
  }

  &__title {
    color: inherit;
  }
}
