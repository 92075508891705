.teaser__item.col-sm-6 {
  @include media(">=tablet", "<desktop") {
    &:nth-child(3) {
      clear: both;
    }
  }
}
/*
.teaser__item {
  border: 1px solid color('primary', "base");
  padding: 15px;
}
*/

.teaser:not(.teaser--person) {
  // ticket #18019-border weg | border: 1px solid color("primary","base");
  padding: 15px;
}

.teaser__list {
  .teaser__item {
    margin-bottom: 30px;
  }
}

.teaser {
  $root: &;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;
  @extend .card;

  &__header {
    @extend .card__header;
  }

  &__body {
    //position: relative;
    @extend .card__body;
  }

  &__footer {
    @extend .card__footer;
  }

  &__image {
    @extend .card__image;

    img {
      height: 100%;
    }
  }

  &__date {
    @extend .card__date;
  }

  &__title {
    @extend .card__title;
    font-size: 21px;

    &,
    > a {
      color: inherit;
    }

    @include media("<=phone") {
      font-size: $font-size-h3-mobile;
    }
  }

  &__description {
    @extend .card__description;
    margin: 0 0 8px;
    font-size: 16px;
  }

  &__tagContainer {
    @extend .card__tagContainer;
  }

  &__readMore {
    margin-bottom: 16px;
    display: inline-block;
    font-size: 16px;
  }

  &__list {
    margin-top: 10px;
  }

  //////// &.teaser--short modifier
  &--short {
    display: flex;
    width: auto;
    height: auto;
    @extend .card--short;
    @extend .card--aside;
    @include media("<=tablet") {
      flex-direction: row;
    }
  }

  // person
  &--person {
    @extend .card--person;

    flex-direction: column;

    #{$root}__image {
      margin-bottom: 5px !important;

      img {
        max-height: 85px;
      }
    }

    #{$root}__title {
      @extend .card__title;

      color: $color-black;
    }

    #{$root}__position {
      @extend .card__position;
    }

    .teaser__title {
      margin-bottom: 5px !important;
      margin-top: 5px !important;
    }

    .teaser__phone {
      margin: 2px 0 0 0;
    }

    .teaser__body {
      position: initial;

      display: block !important;
    }

    &:hover {
      opacity: 1;
    }
  }

  &--detail {
    @extend .card--detail;

    #{$root}__title {
      a::after {
        display: none;
      }
    }
  }

  & #{$root}__data {
    margin: 0;
  }

  &__body {
    @extend .card__data;

    .teaser__pageType {
      color: color("primary");

      font-size: 14px;
      font-weight: 600;

      margin-bottom: 1px;
    }

    .teaser__title {
      margin-top: 0;
    }
  }

  // tag
  &--tag {
    @extend .card--tag;
  }

  &__tag {
    @extend .card__tag;
  }

  // flip classes
  &--flip {
    @extend .card--flip;
  }

  &__sideWrap {
    @extend .card__sideWrap;
  }

  &__back {
    @extend .card__back;
  }

  &__background {
    @extend .card__background;
  }

  &__front {
    @extend .card__front;
  }

  // backdrop
  &--backdrop {
    @extend .card--backdrop;
  }

  &__tags {
    padding: 0;
    margin: 0;

    list-style-type: none;

    display: flex;
  }
}
