$input-accent-color: color("primary") !default;
$input-disabled-color: color("background", "dark") !default;
$input-error-color: color-pick("danger", $state-colors) !default;

%input-text {
  $root: &;
  position: relative;
  box-sizing: border-box;

  min-height: 3.9rem;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  vertical-align: middle;

  border: 0.1rem solid #ddd;
  border-radius: 0;
  outline: none;

  background-color: $color-white;

  &:focus {
    border-color: $input-accent-color;
  }

  &:disabled {
    background-color: $input-disabled-color;
    cursor: not-allowed;
  }

  @at-root input[type="text"],
    input[type="date"],
    input[type="url"],
    input[type="email"],
    input[type="password"],
    input[type="number"] {
    @extend #{$root};
  }
}

%input-search {
  $root: &;

  @extend %input-text;
  appearance: none;

  padding: 0 0.4rem;

  @at-root input[type="search"] {
    @extend #{$root};
  }
}

%input-error-outlined {
  border-color: $input-error-color !important;
}

%input-error {
  margin: 0;
  padding: 15px 1em 0 1em;
  margin-top: -20px;
  list-style-type: none;

  display: inline-block;

  line-height: 2;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.015em;
  border-radius: 0;

  background-color: $input-error-color;
  color: text-color($input-error-color);
}

%input-textarea {
  $root: &;
  @extend %input-text;

  display: block;
  overflow: auto;

  @at-root textarea {
    @extend #{$root};
  }
}

%input-select {
  $root: &;

  position: relative;
  color: inherit;

  &::after {
    content: "\25BE";
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: #ddd;
    pointer-events: none;
  }

  /* Target IE9 and IE10 */
  select::-ms-expand {
    display: none;
  }

  select {
    appearance: none;

    height: 3.9rem;
    width: 100%;
    padding-left: 1rem;
    margin-bottom: 1rem;
    font-family: inherit;
    font-size: 1.4rem;

    border: 0.1rem solid #ddd;
    border-radius: 0;
    outline: none;

    background-color: white;

    &:focus {
      border-color: $input-accent-color;
    }

    &:disabled {
      background-color: $input-disabled-color;
      cursor: not-allowed;
    }
  }

  @at-root .select-wrap {
    @extend #{$root};
  }
}

%input-select-multiple {
  $root: &;
  appearance: none;

  width: 100%;
  padding: 0 1rem;
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: 1.4rem;

  border: 0.1rem solid #ddd;
  border-radius: 0.3rem;
  outline: none;

  background-color: white;

  &:focus {
    border-color: $input-accent-color;
  }

  &:disabled {
    background-color: $input-disabled-color;
    cursor: not-allowed;
  }

  @at-root select[multiple] {
    @extend #{$root};
  }
}

%input-checkbox {
  $root: &;

  position: absolute;
  left: -100rem;

  + label {
    position: relative;
    cursor: pointer;
    margin-right: 1.5rem;
    padding-left: 2.5rem;
    font-size: 1.4rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1.6rem;
      width: 1.6rem;
      border-radius: 0;
      background-color: #ddd;
      font-family: sans-serif;
    }

    &::after {
      content: "";
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  /* Not depending on the user's font here improves consistency. */
  &:checked + label::after {
    content: "";
    position: absolute;
    display: block;
    opacity: 1;
    border-width: 0 0.3rem 0.3rem 0;
    transform: rotate(45deg);
    line-height: 1.6rem;
  }

  &:focus + label::before {
    box-shadow: 0 0.1rem 0 #ddd;
  }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::before {
        background-color: $input-disabled-color;
      }
    }

    &:checked + label::after {
      border-color: $input-disabled-color;
    }
  }

  @at-root input[type="checkbox"] {
    @extend #{$root};
  }
}

%input-radio {
  $root: &;

  position: absolute;
  left: -100rem;

  + label {
    position: relative;
    cursor: pointer;
    margin-right: 1.5rem;
    padding-left: 2.5rem;
    font-size: 1.4rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1.8rem;
      width: 1.8rem;
      border-radius: 0;
      background-color: #ddd;
      font-family: sans-serif;
    }

    &::after {
      content: "";
      transition: background-color 0.3s;
    }
  }

  &:checked + label::after {
    content: "";
    position: absolute;
    border-radius: 0;
    background-color: $input-accent-color;
    text-align: center;
  }

  &:focus + label::before {
    box-shadow: 0 0.1rem 0 #ddd;
  }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::before {
        background-color: $input-disabled-color;
      }
    }

    &:checked + label::after {
      background-color: shade($input-disabled-color, 30%);
    }
  }

  @at-root input[type="radio"] {
    @extend #{$root};
  }
}
