$mobile-menu-background: $color-white;
$mobile-header-background: $color-white;
$mobile-header-burger-position: left;

.mobile-header {
  display: none;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  right: 0;
  left: 0;
  top: 0;

  backface-visibility: hidden;
  z-index: 10000000;

  box-shadow: 0 2px 4px 0 rgba($gray-lighter, 0.5);
  background-color: $mobile-header-background;

  border-bottom: 7px solid color("accent");

  &.is-open {
    box-shadow: 0 2px 4px 0 rgba($gray-lighter, 0);
    background-color: #4c4c4c;
    border-bottom-color: #482200;
  }

  @include text-color($mobile-header-background);
  @include media("<=tablet") {
    display: flex;

    height: 60px;
  }

  .hamburger-inner {
    &,
    &::before,
    &::after {
      transition: all ease 400ms;
    }
  }

  .hamburger.is-active {
    .hamburger-inner {
      &,
      &::before,
      &::after {
        background-color: $hamburger-layer-color-active !important;
      }
    }
  }
  &__burger {
    position: absolute;
    top: 7px;
    @if ($mobile-header-burger-position==right) {
      order: 1;
    } @else if ($mobile-header-burger-position==left) {
      order: 0;
    }
    .hamburger {
      margin-bottom: $hamburger-layer-spacing * -1;
    }
  }
  &__logo {
    flex: 1;
    padding: 5px;
    text-align: center;
    img {
      max-height: 35px;
      width: auto;
    }
  }
}

@include media("<=phone") {
  .mobile-header {
    .mobile-header__logo {
      margin-left: 60px;
    }
    .header__logo {
      img {
        max-width: 180px;
      }
    }
  }
}
