$header-is-fixed: false;

.header {
  margin-left: -15px;
  margin-right: -15px;

  @include media("<=tablet") {
    display: none;
  }

  position: relative;

  z-index: 1000;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  border-bottom: 7px solid color("accent");

  @if ($header-is-fixed == true) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  &__logo {
    width: 100%;
    max-width: 250px;

    align-self: flex-start;
//  margin-top: 40px;
//  margin-bottom: 20px;

    margin-bottom: -28px;
    margin-top: 25px;

    @include media (">991px", "<1200px") {
      max-width: 200px;
    }
  }

  &__nav {
    // creates a bug on IE11
    // not that important to layout, so throw it out.
    //flex: 1;
    justify-self: flex-end;
  }
}
