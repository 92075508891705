$font-size-base: 16px;
$font-size-large: ceil(($font-size-base * 1.25));
$font-size-small: ceil(($font-size-base * 0.85));

/// Background color for `<body>`.
$body-bg: #fff;
/// Global text color on `<body>`.
$text-color: #363636;

// (currently only mobile)
// Theme Color for the Browser
$browser-color: color("accent");

/// Global textual link color.
$link-color: color("accent");
$link-hover-color: color("accent", "dark");
$link-hover-decoration: underline;

$headings-line-height: 1.33;
