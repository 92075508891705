$searchbox-action-width: 32px;
$searchbox-query-height: 38px;
$searchbox-width-expanded: 600px;
$searchbox-width-static: 760px;

.search-result {
  a {
    color: inherit;
    text-decoration: none;
  }
}

.searchbox {
  $root: &;
  position: static;

  %icon-button {
    font-size: 0;
    width: $searchbox-action-width;
    height: $searchbox-query-height;
    box-sizing: border-box;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    &::before {
      font-size: 1.6rem;
    }
  }

  &__close-button {
    @extend %icon-button;
    position: absolute;
    top: 0;
    right: 100%;
  }

  &__send-button {
    @extend %icon-button;
    position: relative;
    z-index: 1;
    right: 0;
  }
  /**
  Staic mode styles */
  &--static {
    #{$root} {
      &__field {
        > input {
          min-width: $searchbox-width-static;
        }
      }
    }
  }
  /**
  Expanding mode styles */
  &--expanding {
    height: $searchbox-query-height;
    z-index: 100 * 100 * 100;

    &:not(.is-expanded) {
      #{$root} {
        &__close-button {
          display: none;
        }
      }
    }

    &.is-expanded {
      #{$root} {
        &__field-shadow {
          width: $searchbox-width-expanded;
          pointer-events: all;
        }
      }
    }

    #{$root} {
      &__query-preview {
        position: absolute;
        right: 0;
        left: initial;

        width: $searchbox-width-expanded;
        top: 100%;
        overflow: hidden;

        &-sizer {
        }
      }

      &__send-button {
        //position: relative;
      }

      &__field {
        &-shadow {
          position: absolute;

          top: 0;
          right: 100%;

          //width: 0;
          overflow: hidden;

          pointer-events: none;

          > input {
            height: $searchbox-query-height;
            width: $searchbox-width-expanded;

            &:valid ~ label {
              display: none;
            }

            ~ label {
              position: absolute;
              left: $searchbox-query-height * 0.3;
              top: 0;

              line-height: $searchbox-query-height;

              white-space: nowrap;
              pointer-events: none;
              user-select: none;
            }
          }
        }
      }
    }
  }
  /**
  Query result positioning */
  &__query-preview {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    overflow: hidden;
    z-index: 100 * 100 * 100;
    display: flex;
    flex-direction: column;

    &:not(.is-active) {
      display: none;
    }

    &-header {
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: rgba(black, 0.15);
    }
  }

  &__field {
    position: relative;
    display: inline-block;

    > input {
      margin: 0;
      width: 100%;
      padding-right: $searchbox-action-width * 2;

      &:valid + label {
        display: none;
      }
    }

    > label {
      position: absolute;
      user-select: none;
      left: 1rem;
      top: 50%;
      font-style: italic;
      transform: perspective(1px) translateY(-50%);
    }
  }


}

$searchbox-timing-function: $easeOutExpo;
$searchbox-animation-time: 600ms;
$searchbox-result-background: $color-white;
$searchbox-focused-color: color("primary");

.search-ri {
  background: $searchbox-result-background;

  &.is-focused {
    background: shade($searchbox-result-background, 12%) !important;
  }

  &:nth-child(odd) {
    background: shade($searchbox-result-background, 8%);

    &.is-focused {
      background: shade($searchbox-result-background, 16%) !important;
    }
  }
}

.searchbox {
  $root: &;

  html & {
    /**
  Staic mode styles */
    &--static {
      #{$root} {
        &__field {
          // field-containee styles when mode "static"
        }
      }
    }
    /**
  Expanding mode styles */
    &--expanding {
      &:not(.is-expanded) {
        #{$root}__field-shadow {
          opacity: 0;
        }
      }

      &.is-expanded {
        #{$root} {
          &__send-button {
            margin-right: $grid-gutter-half * 0.5;
            margin-left: $grid-gutter-half * -0.5;
          }
        }
      }

      #{$root} {
        &__close-button {
          @extend .slideInRight;
          //($duration: $searchbox-animation-time, $function: $searchbox-timing-function

          background: none;
          opacity: 0.75;
        }

        &__send-button {
          background: none;
          transition: none $searchbox-timing-function $searchbox-animation-time;
          transition-property: margin-left, margin-right;
        }

        &__field {
          // field-containee styles when mode "expanding"

          &-shadow {
            // Remove this line, if the textbox should start on the left side of the button
            right: 0;

            > input {
            }
          }
        }
      }
    }

    &__close-button {
      text-align: center;
    }

    &__send-button {
      text-align: center;
    }

    /**
  Query result stylings */
    &__query-preview {
      background: $body-bg;
      box-shadow: 0px 3px 14px rgba(color("primary"), 0.4);
      border-radius: 7px;

      > :last-child,
      .alert {
        margin-bottom: 0;
      }

      .searchbox__query-preview-header {
        margin: 0;
        font-size: 1em;
        background-color: transparent;
        padding: $grid-gutter-half * 0.75 $grid-gutter-half;
      }

      .search-results__nothing-found {
        .alert-info {
          border: 0 !important;
          font-size: 1em;
          color: initial;
        }
      }

      .search-result {
        $root: unquote(".search-result");

        border: none;
        margin: 0;
        padding: 0 1em;
        position: relative;

        transition: opacity 200ms ease, background-color 200ms ease;

        &:hover {
          #{$root}__title {
            color: color("primary");
          }
          #{$root}__details {
            opacity: 0.8;
          }
        }

        &.is-focused {
          background: $searchbox-focused-color;
          color: text-color($searchbox-focused-color);

          &:hover {
            opacity: 0.75;
          }

          #{$root}__title {
            color: lighten(color("primary"), 55%);
          }
          #{$root}__details {
            color: lighten(color("primary"), 20%);

            mark {
              color: lighten(color("primary"), 40%);
            }
          }
        }

        &__title {
          font-size: 1.2em;
          line-height: 1.2;
          font-weight: 400;
          text-transform: none;
          margin-bottom: 4px;
          transition: color 200ms ease;

          mark {
            padding: 0;
            color: inherit;
          }
        }

        &__details {
          text-transform: none;
          color: lighten($text-color, 50%);
          line-height: 1.2;
          font-size: 0.9em;
          transition: color 200ms ease;

          > img {
            display: none !important;
          }

          mark {
            color: lighten($text-color, 25%);
            padding: 0;
            font-weight: bolder;
            font-style: italic;
            transition: color 200ms ease;
          }
        }

        a {
          display: block;

          margin: 0;
          padding: 10px 0;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .search-result__title,
          .type {
            font-size: 16px;
          }

          &:hover {
            &::before {
              background-color: $gray;
              z-index: -1;
            }
          }
        }
      }

      // Result header ("Showing results for xxx")
      &-header {
        @extend .fadeIn;

        margin: #{-$grid-gutter-half} #{-$grid-gutter-half} 0;
        font-size: rem(12);
        padding: 5px $grid-gutter-half;
      }
    }

    &__field {
      &-shadow {
        // shadower animates, not the parent field
        transition: opacity $searchbox-animation-time ease;
      }

      // styles for input field
      input {
        //border-radius: 1000px;
      }

      // styles for label (styleable placeholder)
      label {
      }
    }
  }
}
