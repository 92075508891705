/*
      GENERAL GRID & CONTAINER SETTINGS
*/

// overall, global max-width of <body>
$document-max-width: 2200px;

// Grid-grid with multiple layouting
$grid-columns: 12;

$flex-columns: 12; // normal flex-based grid

// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;

// Half of $grid-gutter-width, for lazyness
$grid-gutter-half: $grid-gutter-width / 2;

// Vertical spacing for Typo3 elements
$vertical-element-spacing: (
  default: (
    $grid-gutter-width * 1,
    $grid-gutter-width * 2,
    $grid-gutter-width * 4
  ),
  tablet: (
    $grid-gutter-width * 0.666,
    $grid-gutter-width * 1.333,
    $grid-gutter-width * 2
  ),
  phone: (
    $grid-gutter-width * 0.666,
    $grid-gutter-width * 1.333,
    $grid-gutter-width * 2
  )
);

/*
      CONTAINER WIDTH DEFINITIONS
*/

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;
