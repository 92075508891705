#course-dates, #certificate-course {
  $red: #c13333;
  $yellow: #ffba32;

  .course-dates--headline {
    margin-bottom: 5px;
  }

  .contact {
    @include media('<=tablet') {
      margin-top: 20px;
    }

    strong {
      display: inline-block;
      margin-bottom: 0.333em;
    }

    .name, .position, .phone, .email, .workshop__name, .workshop__street, .workshop__city, .workshop__phone, .workshop__fax, .workshop__email, .workshop__website {
      margin-bottom: 0;
    }

    figure {
      &.image {
        img {
          border-radius: 100%;
          margin-bottom: 0.333em;
        }
      }
    }
  }


  .infotext {
    color: color("accent");
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding: 0 0 7px 0;
  }

  .accortabs {
    $root: unquote(".accortabs");
    &#{$root}--accordion {
      #{$root}__title {
        justify-content: flex-start;
        min-height: 45px;

        > .grow {
          flex: 1;

          > span:not(:last-child) {
            margin-right: 16px;
          }

          @include media('<=tablet') {
            display: flex;
            flex-direction: column;

            margin-top: -100%;
          }
        }

        .city {
          font-weight: bold;

          @include media('<=tablet') {
            margin-top: -23px;
          }
        }

        @include media ("<=tablet") {
          position: relative;

          flex-direction: column;
          align-items: flex-start;

          width: 100%;
        }
      }

      #{$root}__section {
        padding: 0 $grid-gutter-half;

          border: 1px solid $accortabs-border;
          border-top: 0;
          border-bottom: 0;

          &.is-visible {

            border-bottom: 1px solid $accortabs-border;

            margin-top: -5.333px;
            margin-bottom: 5.333px;
            padding-top: 15px;
            padding-bottom: 15px;
          }
      }
    }
  }

  .workshop {
    $root: unquote(".workshop");

    p {
      margin: 0;
      &.guarantee {
        color: white;
        background-color: color("primary","base");
        text-transform: uppercase;
        font-size: 14px;
        display: inline-block;
        padding: 0 3px;
      }
    }

    a {
      font-size: 14px;
      font-weight: normal;
    }

    &__row {
      display: grid;
      /*
      grid-gap: 15px 30px;
      grid-template-columns: 2fr 2fr 3fr;
      */
      display: grid;
      grid-column-gap: 4.5%;
      grid-template-columns: 30% 26% 35%;
      box-sizing: border-box;
      overflow-wrap: break-word;

      @include media('<=tablet') {
        grid-template-columns: 1fr;
      }

      &.has-seperators {
        padding-top: 23px;

        margin-top: 23px;

        border-top: 1px solid color("accent");
        border-left: none;
        border-right: none;
      }
    }

    &__row--twofr {
      grid-template-columns: 2fr 1.45fr;

      @include media('<=tablet') {
        grid-template-columns: 1fr;
      }
    }

    &__sign-up {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      grid-column-end: -1;

      @include media('<=tablet') {
        margin-top: 10px;
      }

      form,
      .btn {
        @include media('<=tablet') {
          width: 100%;
        }
      }
    }

    &__waitlist {
      @include style-button(
        (
          border: transparent,
          bg: $red,
          text: $color-white,
          template: "button-base"
        )
      );

      font-weight: 600;
    }

    &__price {
      @include media ("<=tablet") {
        margin-top: 10px;
      }

      .workshop__price-information {
      }
    }

    &__location {
      .workshop__db-link {
        margin-top: 20px;

        @include media ("<=phone") {
          margin-top: 10px;
        }
      }

      .workshop__location-contact {
        margin-top: 20px;

        @include media ("<=phone") {
          margin-top: 15px;
        }
      }

      .workshop__additional-information {
        margin-top: 20px;

        @include media ("<=phone") {
          margin-top: 15px;
        }
      }

      strong {
        display: inline-block;
        margin-bottom: 0.333em;
      }

      .name, .position, .phone, .email, .workshop__name, .workshop__street, .workshop__city, .workshop__phone, .workshop__fax, .workshop__email, .workshop__website {
        margin-bottom: 0;
      }

    }

    &__hotels .accortabs {
      &__title {

        @include media('<=tablet') {
          padding-bottom: 0 !important;
        }

        > :first-child {
          flex: 1;

          @include media('<=tablet') {
            line-height: 1.5;
            padding: 10px 0;
          }
        }
      }
    }
  }

  .workshop-status {
    $root: unquote(".workshop-status");

    position: relative;
    border: 1px solid;
    border-radius: 3px;

    margin-right: 24px;

    text-transform: uppercase;
    line-height: 1.5;

    padding: 0 10px;
    padding-bottom: 0.1333em;

    &--pending {
      @extend #{$root};

      position: absolute;

      left: 15px;

      color: $gray-darker;

      @include media ("<=tablet") {
        margin-top: 8px;
      }
    }

    &--full {
      @extend #{$root};

      color: $red;

      @include media ("<=tablet") {
        margin-bottom: 12px;
      }
    }

    &--generic {
      @extend #{$root};

      color: $yellow;

      @include media ("<=tablet") {
        margin-bottom: 12px;
      }
    }

    &--guaranteed {
      @extend #{$root};

      color: white;
      background-color: color("primary","base");
    }
  }

  .price-infos {
    margin-top: 30px;

    @include media ("<=phone") {
      margin-top: 20px;
    }

    .price-infos--headline {}
    .price-infos--subline {
      margin-bottom: 0;
    }
    .price-infos--list {
      padding-left: 20px;
      list-style-image: none;
    }
  }
}
@include media ("<=tablet") {
  .accortabs__title {
    &::after {
      position: absolute;

      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.powermail_fieldwrap_anmeldungfuer {
  .form-course-info__container {
    .form-course-info__subline,
    .form-course-info__subline h5 {
      color: black;
    }

    .module .module__info {
      background-color: #fff !important;

      .date {
        color: black;
      }
    }
  }
}

.form-course-info__subline h5 {
  color: black !important;
}

.form-course-info .course .form-course-info__items {
  background-color: #fff !important;
}

.teaser--person {
  .teaser__image img{
    width: 130px !important;
    height: 130px !important;
    max-width: 130px !important;
    max-height: 130px !important;
  }
}
.contact .image img {
  max-width: 130px !important;
  max-height: 130px !important;
}
