@mixin style-button(
  $opts: (
    border: false,
    bg: false,
    text: false,
    template: "button-base"
  )
) {
  @if (map-has-key($opts, "template")) {
    @extend %#{map-get($opts, "template")};
  }

  @if (map-has-key($opts, "bg")) {
    background: map-get($opts, "bg");
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    @if (map-has-key($opts, "bg")) {
      background: lighten(map-get($opts, "bg"), 14%) !important;
    }
  }

  &:active,
  &:focus {
    @if (map-has-key($opts, "bg")) {
      background: shade(map-get($opts, "bg"), 8%);
    }
  }

  @if (map-has-key($opts, "border")) {
    &,
    &:hover,
    &:focus {
      border-color: map-get($opts, "border");
    }
  }

  @if (map-has-key($opts, "text")) {
    color: map-get($opts, "text");
  } @else {
    color: text-color(map-get($opts, "bg"));
  }
}
