$one-line: $font-size-base;
$quotes-type: common !default;

%typography-spacing-layout {
  // nothing has margin, by default
  margin: 0;

  // <mt> of half paragraph FOR
  // Paragraph followed by ANY
  // OR
  // ANY followed by ANY
  + p,
  & + & {
    margin-top: $one-line * 0.5;
  }

  // twice the height FOR
  // ANY after paragraph
  p + & {
    margin-top: $one-line * 2;
  }
}

%heading {
  @extend %typography-spacing-layout;

  word-wrap: break-word;
  color: $headings-color;

  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

// Setup Basic Font
@for $headingSize from 1 through 6 {
  .h#{$headingSize},
  h#{$headingSize} {
    @extend %heading;
  }
}

.h1,
h1 {
  font-size: $font-size-h1;

  hyphens: auto;

  @include media("<=phone") {
    font-size: $font-size-h1-mobile;
  }
}

.h2,
h2 {
  font-size: $font-size-h2;

  hyphens: auto;

  @include media("<=phone") {
    font-size: $font-size-h2-mobile;
  }
}

.h3,
h3 {
  word-wrap: break-word;

  font-size: $font-size-h3;

  hyphens: auto;

  @include media("<=phone") {
    font-size: $font-size-h3-mobile;
  }
}

.h4,
h4 {
  word-wrap: break-word;

  font-size: $font-size-h4;

  @include media("<=phone") {
    font-size: $font-size-h4-mobile;
  }
}

.h5,
h5 {
  color: gray;
  word-wrap: break-word;
  font-size: $font-size-h5;

  @include media("<=phone") {
    font-size: $font-size-h5-mobile;
  }
}

.h6,
h6 {
  color: gray;
  word-wrap: break-word;
  font-size: $font-size-h6;

  @include media("<=phone") {
    font-size: $font-size-h6-mobile;
  }
}

// also applied on <p>'!
.text {
  margin: 0;
  line-height: $line-height-base;

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  @at-root p {
    @extend .text;
  }

  &:not(:last-child) {
    margin-bottom: $one-line;
  }

  &--lead {
    color: $lead-color;
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;

    @include media("<=phone") {
      font-size: 18px;
    }
  }

  // links in text, colorize
  p,
  ul,
  ol,
  li {
    a {
      color: $link-color;

      &:hover,
      &:focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
      }
    }
  }
}

ul {
  list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Ccircle cx='4' cy='4' r='2.5' fill='%23EF6F00' fill-rule='evenodd' stroke='%23EF6F00' stroke-width='3'/%3E%3C/svg%3E");
}

.ce-bodytext {
  ul {
    li {
      margin-top: 10px;
    }
  }
}

%no-link,
.no-link {
  color: currentColor;

  &,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: none !important;
  }

  &--invert {
    color: text-color($color-black);
  }
}

//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

//
// Figures
//

figure {
}

figcaption {
  line-height: 1.3;
}

//
// Emphasis
//

small,
.small {
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

//
// Misc
//

a {
  font-weight: $font-weight-bold;
  transition:.1s;
  &:focus {
    outline: none !important;
  }
  &:hover {
    transition:.1s;
  }
}

abbr,
.initialism {
  $this: &;
  font-size: 90%;
  text-transform: uppercase;
}

.p--invert {
  color: $color-white;
}

.blockquote {
  $quote-border-width: 100px;
  $quote-border-thickness: 3px;
  $quote-color: color-pick("blockquote");
  $quote-background: color("accent");
  $quote-font-size: round($font-size-base * 1.66);

  margin: 0 0 25px 0;
  padding: 20px 1em;

  position: relative;
  border: none;

  font-size: $quote-font-size !important;
  font-weight: 400;

  text-align: center;

  color: $quote-color;

  @at-root blockquote {
    @extend .blockquote !optional;
  }

  p {
    font-size: inherit !important;
    line-height: inherit;
  }

  > * {
    display: inline;
  }

  @if ($quotes-type == common) {
    &::after,
    &::before {
      content: "";
      position: absolute;

      left: 50%;
      margin-left: ($quote-border-width / 2) * -1;

      width: $quote-border-width;
      height: $quote-border-thickness;

      background: $quote-background;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    // modifier styles for left/right
    &--left,
    &--right {
      font-size: $font-size-h4;

      &::after {
        content: none;
      }

      &::before {
        margin: 0;

        left: auto;

        top: 0;
        bottom: 0;

        height: auto;
        width: $quote-border-thickness;
      }
    }

    // modifier position left
    &--left {
      text-align: left;

      &::before {
        left: 0;
      }
    }

    // modifer position right
    &--right {
      text-align: right;

      &::before {
        right: 0;
      }
    }
  } @else {
    &::after,
    &::before {
      font-size: inherit !important;
    }

    &::before {
      content: "\201E";
    }

    &::after {
      content: "\201C";
    }
  }
}

hr {
  background: color("base-gray");
}

.ce-bodytext {
  h1 + ul, .h1 + ul, h2 + ul, .h2 + ul, .h3 + ul, h3 + ul, .h4 + ul, h4 + ul, .h5 + ul, h5 + ul, .h6 + ul, h6 + ul {
    margin-top: 8px;
  }

  ul + .h1, ul + h1, ul + .h2, ul + h2, ul + .h3, ul + h3, ul + .h4, ul + h4, ul + .h5, ul + h5, ul + .h6, ul + h6 {
    margin-top: 32px;
  }

  ul, ol {
    margin-bottom: 16px;
  }

  ul ul {
    list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Ccircle cx='4' cy='4' r='2.5' fill='transparent' fill-rule='evenodd' stroke='%23EF6F00' stroke-width='2'/%3E%3C/svg%3E")
  }

  ul li {
    margin-top: 10px
  }
}

