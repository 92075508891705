@import 'functions/general';
@import 'functions/math';
@import 'functions/color-helpers';


/// Converts pixel value to rem unit.
/// Make sure :root has been set to `$document-base-size`!
/// @access public
/// @param {Number} $pxval - Pixel value to convert into rem
/// @return {Number}
@function rem($pxval) {
  @return ($pxval / strip-unit($document-base-size)) * 1rem;
}
