.hotel {
  .name {
    font-weight: bold;
  }

  .hotel__contact {
    margin-top: 20px;

    @include media("<=tablet") {
      margin-top: 10px;
    }
  }

  .directions {
    margin-top: 20px;

    @include media("<=tablet") {
      margin-top: 10px;
    }
  }

  .description {
    margin-top: 25px;

    @include media("<=tablet") {
      margin-top: 15px;
    }
  }
}

#course-dates {
  .workshop__hotels {
    .workshop__hotels-headline {
      margin-bottom: 0.333em;
    }
  }
}

.workshop__hotels {
  @include media("<=tablet") {
    margin-top: 20px;
  }

  .accortabs {
    @include media("<=tablet") {
      margin-bottom: 5px;
    }
  }
}
