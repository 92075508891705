$menu-background: $body-bg;
$menu-item-color: color("accent");
$menu-search-background: transparent;
$menu-border-fill: color("background", "dark");
$menu-arrow-color: color("accent");

@include media("<=tablet") {
  #view-box-site {
    &::after {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      z-index: 999999;

      transition: background-color ease 300ms;
    }
  }
}

.mobile-menu {
  $root: &;

  @include media(">=desktop") {
    display: none;
  }

  &.is-open ~ #view-box-site {
    box-shadow: 20px 0 20px 20px rgba(black, 0.3);

    &::after {
      content: "";
      display: block !important;
      background-color: rgba(black, 0.7);
    }
  }

  &.submenus-open {
  }

  &__search {
    background-color: $menu-search-background;
    border-bottom: 1px solid $menu-border-fill;

    position: relative;
    z-index: 0;

    form {
      display: flex;

      > div {
        &,
        > .searchbox {
          display: block;
          width: 100%;
        }
      }

      .searchbox {
        position: relative;

        .search-result {
          .type {
            font-size: 14px;
          }
          .search-result__title {
            font-size: 14px;
          }
          .search-result__details {
            display: none;
          }
        }

        &__query-preview {
          padding: 0 15px;
          border-radius: 0;
          box-shadow: 0 1px 3px rgba($color-black, 0.33);

          background-color: $gray;
        }

        &__field {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          > button {
            position: relative;
            background: none;
          }
        }

        &__field-shadow {
          position: relative;
          overflow:hidden;
          margin-left: 22px;
          width: 74%;

          > label {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            align-items: center;
            padding-left: 10px;

            font-size: 12px;
          }

          > input {
            border: none;
            background-color: #eee;
            border-radius: 10px;

            margin: 0 0 6px 0;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 14px;

            font-size: 14px;
            line-height: 32px;
            height: 32px;
          }

          > input:valid + label {
            display: none;
          }

          > label,
          > input {
            height: 32px;
            min-height: 0;
            line-height: 32px;
          }
        }
      }

      button.searchbox__send-button,
      button.searchbox__close-button {
        position: absolute;
        top: 0;
      }

      button.searchbox__send-button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 32px;

        cursor: pointer;
        z-index: 0 !important;
      }
    }
  }

  &__items {
    position: relative;

    background-color: $menu-background;

    padding: 0;
    margin: 0;

    list-style: none;
    overflow-x: hidden;

    display: flex;
    flex-flow: column;

    // hide active or non-translated language switcher items
    .mobile-menu__item {
      &.active,
      &.no-translation {
        display: none;
      }
    }
  }

  &__link {
    display: block;

    width: 100%;
    min-height: 48px;
    padding: 0 24px;

    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;

    color: $menu-item-color;

    line-height: 28px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__back {
    @extend #{$root}__link;

    font-size: inherit;

    min-height: 48px;
    line-height: 48px;

    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    > span {
      flex: 1;
      text-align: center;

      margin-left: 8px;

      color: mix(white, $menu-item-color);
      font-size: 16px;
    }

    &::before {
      @extend .fa;
      @extend .fa-angle-left:before;
      color: $menu-arrow-color;
    }
  }

  &__title {
    @extend #{$root}__back;

    text-align: center;
    text-transform: none;

    padding: 10px 0 0 0;

    font-size: 1.4em;
    font-weight: 300;

    color: color("blue-gray");

    &::before {
      content: none;
    }
  }

  &__item {
    border-bottom: 1px solid $menu-border-fill;

    &.has-submenu {
      display: flex;
    }

    &.has-submenu > #{$root}__dropee {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;

      border-left: 1px solid $menu-border-fill;

      &::after {
        @extend .fa;
        @extend .fa-caret-right:before;

        font-size: 24px;
        color: $menu-arrow-color;
      }
    }

    > #{$root}__items.is-submenu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      will-change: transform;

      transform: translateX(100%);
      transition: transform cubic-bezier(0.33, 0, 0, 1) 300ms;

      border-left: 1px solid darken($color-white, 12%);
    }

    &.is-active {
      > #{$root}__items {
        transform: translateX(0);
      }
    }
  }
}
