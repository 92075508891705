$select-panel-background: $color-white;
$select-background-state: shade($select-panel-background, 15%);
$select-panel-transition: transform 600ms $authenticMotion;

$sassy-base-color: #dddddd;
$sassy-accent-color: color("accent");
$sassy-disabled-color: $gray-lighter;

.select,
input,
textarea {
  max-width: $input-max-width;
}

.btn.btn-filestyle {
  height: 43px;
  margin: 0;
  margin-left: -2px;
}

%input-text,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="text"],
input[type="url"] {
  box-sizing: border-box;
  display: block;

  min-height: rem(39);
  width: 100%;
  padding: rem(10);
  margin-bottom: rem(10);
  font-family: inherit;
  font-size: rem(14);
  vertical-align: middle;

  border: rem(1) solid #ddd;
  border-radius: 0;
  outline: none;

  background-color: $body-bg;
  color: text-color($body-bg);

  &:focus {
    border-color: $sassy-accent-color;
  }

  &:disabled {
    background-color: $sassy-disabled-color;
    @include text-color($sassy-disabled-color);

    cursor: not-allowed;
  }
}

input[type="search"] {
  @extend %input-text;

  appearance: none;
  padding: 0 rem(4);
}

textarea {
  @extend %input-text;

  display: block;
  overflow: auto;
}

input[type="checkbox"],
input[type="radio"] {
  // apply only on valid checkboxes
  &[for],
  &[id],
  &[name] {
    position: absolute;
    left: -1000rem;
  }

  &:focus {
    + label::before {
      box-shadow: inset 0 0 0 rem(2) darken($sassy-base-color, 30%);
    }
  }

  + label {
    position: relative;
    cursor: pointer;

    display: inline-block;
    vertical-align: baseline;

    margin-right: rem(15);
    padding-left: rem(25);
    font-size: rem(14);

    user-select: none;

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      height: rem(16);
      width: rem(16);
    }

    &::before {
      border-radius: 0;
      box-shadow: inset 0 0 0 rem(1) $sassy-base-color;
      font-family: sans-serif;
    }

    &::after {
      content: "";
      background-color: $sassy-accent-color;

      transform: scale3d(0, 0, 1);
      transition: transform $authenticMotion 100ms;

      left: 3px;
      top: 6px;
      width: 1rem;
      height: 1rem;
    }
  }

  &:checked + label::after {
    transform: scale3d(1, 1, 1);
  }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::after {
        background-color: $sassy-disabled-color;
      }
    }

    &:checked + label::after {
      border-color: darken($sassy-disabled-color, 30%);
    }
  }
}

input[type="radio"] {
  + label {
    &::after,
    &::before {
      border-radius: 100% !important;
    }
  }
}

@keyframes slide-select-panel-down {
  0% {
    transform: translate3d(0, -3.9rem, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.select {
  width: 100%;

  position: relative;
  box-sizing: border-box;

  select {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.is-disabled {
    border-color: $sassy-disabled-color;

    .select {
    }
  }

  &.is-open {
    .select {
      &__panel {
        display: block;

        max-height: 25em;
        overflow-y: auto;
      }
    }
  }

  &__opener {
    @extend %input-text;

    position: relative;

    background-color: $select-panel-background;
    @include text-color($select-panel-background);

    &::after {
      content: "\25BE";
      position: absolute;
      top: rem(10);
      right: rem(15);
      color: $sassy-base-color;
      pointer-events: none;
    }

    display: inline-block;
    cursor: pointer;
    z-index: 2;

    &[aria-expanded="true"] {
      z-index: 51;

      ~ .select__panel {
        z-index: 50;
      }
    }
  }

  &__panel {
    animation: slide-select-panel-down 200ms $authenticMotion forwards;

    display: none;

    border: 1px solid $sassy-base-color;

    position: absolute;
    overflow: hidden;

    top: 100%;

    margin-top: -1rem;
    margin-top: calc(-1rem - 3px);

    width: 100%;
    z-index: 1;

    transition: $select-panel-transition;
    background-color: $select-panel-background;
    @include text-color($select-panel-background);
  }

  &__option {
    padding: 0.5em 0.75em;
    cursor: pointer;

    &.has-focus {
      background-color: $select-background-state;
      @include text-color($select-background-state);
    }

    &.is-selected {
      background-color: $sassy-accent-color;
      @include text-color($sassy-accent-color);
    }
  }

  &__optgroup {
    ::before {
      content: attr(data-label);
      display: block;
      padding: 0.5em;
      color: #888;
    }

    > .select__option {
      padding-left: 2em;
    }
  }
}

.bootstrap-filestyle {
  max-width: 600px;
  display: flex;

  .group-span-filestyle {
    width: auto;
  }

  label.btn.btn-filestyle {
    font-size: 12px;

    border: 1px solid $sassy-base-color;
  }
}
