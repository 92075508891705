$hamburger-padding-x: 15px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 26px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $color-black;
$hamburger-layer-color-active: $color-white;
$hamburger-layer-border-radius: 2px;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0;
$hamburger-hover-transition-timing-function: linear;

$hamburger-hover-filter: opacity(100%);

/*
  @see: https://jonsuh.com/hamburgers/

  unquote('3dx'), unquote('3dx-r'), unquote('3dy'),
  unquote('3dy-r'), arrow, arrow-r,
  arrowalt, arrowalt-r, boring,
  collapse, collapse-r, elastic,
  elastic-r, emphatic, emphatic-r,
  slider, slider-r, spin,
  spin-r, spring, spring-r,
  stand, stand-r, squeeze,
  vortex, vortex-r
*/
$hamburger-types: (collapse);

@import "hamburgers/_sass/hamburgers/hamburgers";
// Be sure to check out the config too!
//@import "config";
// Import the mobile-menu source files.
