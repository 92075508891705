.author {
  margin: 0 0 15px;
  padding: 0 15px 0 0;
  display: inline-flex;
  align-items: center;
  position: relative;

  @include media ('<=phone') {
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &:hover {
    .author__name {
      text-decoration: underline;
    }
  }

  figure {
    width: 40px;
    margin-right: 10px;
    display: inline-block;

    .author__image {
      border-radius: 100%;
      width: 100%;
      height: auto;
    }
  }

  &,
  .author__name {
    font-size: 14px;
  }

  .author__name {
    display: inline-block;
    color: gray;
  }
}
