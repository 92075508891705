body {
  width: 100%;
  height: 100%;
}

@include media(">phone") {
  .slideout-menu {
    display: block;
  }
}

@include media("<desktop") {
  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 270px;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    box-sizing: border-box;
    display: none;
  }

  .slideout-menu-left {
    left: 0;
  }

  .slideout-menu-right {
    right: 0;
  }

  .slideout-panel {
    position: relative;
    z-index: 1;
    // will-change: transform;
    background-color: $body-bg;
    min-height: 100vh;
  }

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }

  .slideout-open .slideout-menu {
    display: block;
  }
}
