/// Retrieve a color from a color-map
/// @access public
/// @param {String} $color-section - The index name of the color
/// @param {Map} $color-db - The map to look for
/// @return {Color}
@function color-pick($named, $color-set: $colors) {
  @if (map-has-key($color-set, $named)) {
    @return map-get($color-set, $named);
  } @else {
    @error "color-pick() function takes a color-name ($named) and an color-set to pick from";
  }
}

/// Retrieve a color from a color-map
/// @access public
/// @param {String} $color-section +- The index name of the color
/// @param {String} $color-light - The index name of the color
/// @param {Map} $color-db - The map to look for
/// @return {Color}
@function color($color-set, $color-light: "base", $color-db: $colors) {
  @if (map-has-key($color-db, $color-set)) {
    $c-set: color-pick($color-set, $color-db);

    @if type-of($c-set) == map {
      @return color-pick($color-light, $c-set);
    } @else {
      @return $c-set;
    }
  } @else {
    @error ""#{$color-set}" does not exist";
  }
}

/// Pick a state-color
/// @access public
/// @param {String} $state - The named color state
/// @return {Color}
@function color-state($state) {
  @return color-pick($state, $state-colors);
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix($color-white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix($color-black, $color, $percentage);
}
@function text-color($color) {
  @if (luminance($color) < 0.5) {
    @return $color-white;
  }
  @return $color-black;
}

/// Returns the luminance of `$color` as a float (between 0 and 1)
/// 1 is pure white, 0 is pure black
/// @param {Color} $color - Color
/// @return {Number}
/// @link http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef Reference
@function luminance($color) {
  $colors: (
    "red": red($color),
    "green": green($color),
    "blue": blue($color)
  );
  @each $name, $value in $colors {
    $adjusted: 0;
    $value: $value / 255;
    @if $value < 0.03928 {
      $value: $value / 12.92;
    } @else {
      $value: ($value + 0.055) / 1.055;
      $value: pow($value, 2.4);
    }

    $colors: map-merge(
      $colors,
      (
        $name: $value
      )
    );
  }
  @return (map-get($colors, "red") * 0.2126) +
    (map-get($colors, "green") * 0.7152) + (map-get($colors, "blue") * 0.0722);
}
