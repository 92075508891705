$thumbnail-play-size: 64px;

.embed-responsive {
  &__close {
    position: absolute;
    z-index: 9999;

    top: 15px;
    right: 15px;

    width: 32px;
    height: 32px;

    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    cursor: pointer;

    color: $color-white;
    text-shadow: 0 0 8px rgba(black, 0.7), 0 0 2px black;

    > * {
      pointer-events: none;
    }
  }

  &__thumbnail {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    background-size: cover;
    background-position: center;
  }

  &__caption {
    display: block;
    opacity: 0.8;
    text-align: center;
  }

  &__play {
    position: absolute;

    top: 50%;
    left: 50%;

    margin: ($thumbnail-play-size * -0.5) 0 0 ($thumbnail-play-size * -0.5);
    cursor: pointer;

    color: $color-white;
    text-shadow: 0 0 6px $color-black;

    transition: transform ease 200ms;

    &:hover {
      transform: scale3d(1.25, 1.25, 1);
    }

    &::before {
      font-size: $thumbnail-play-size;
    }
  }
}

.progressive {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0 none;

    object-fit: cover;
    position: relative;
    width: 100% !important;

    &.preview {
      filter: blur(2vw);
      transform: scale(1.05);
    }
    &.hide {
      opacity: 0;
    }
    &.origin {
      position: absolute;
      left: 0;
      top: 0;
      animation: origin 1.5s ease-out;
    }
    &.origin-scale {
      position: absolute;
      left: 0;
      top: 0;
      animation: origin-scale 1s ease-out;
    }
  }
}

@keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
