$navigation-text-color: $color-black;
$navigation-hover-color: color("accent");
$navigation-dropdown-bg: #fff;
$navigation-dropdown-hover: #f7f7f7;
$navigation-dropdown-border: rgba($color-black, 0.24);

.navigation {
  $root: &;

  @extend .list-unstyled;
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    @extend %no-link;
  }

  &__item {
    &.has-childs {
      position: relative;
    }

    &:hover > #{$root}__dropdown {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
      pointer-events: auto;
    }

    > #{$root}__link {
      display: inline-block;
      padding: 0.5em 1em;

      font-size: rem(14);
      font-size: 1.6rem;
      font-weight: 400;

      color: $navigation-text-color;
      font-weight: $font-weight-bold;

      &:focus,
      &:hover {
        color: inherit;
        outline: 0;
        text-decoration: none;
      }
    }

    &:hover,
    &:focus {
      > #{$root}__link {
        color: $navigation-hover-color;
      }
    }
  }

  &__dropdown {
    @extend .list-unstyled;
    display: flex;

    transition: none ease 120ms;
    transition-property: transform, opacity;

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(5px);
    opacity: 0;
    pointer-events: none;

    flex-direction: column;
    align-items: stretch;

    white-space: nowrap;

    background-color: $navigation-dropdown-bg;
    border: 2px solid $navigation-dropdown-border;

    //@include a-box-shadow("menu");
    @include border-radius("menu");

    li {
      a {
        padding: 0.8em 1.2em !important;

        &.navigation__link {
          display: block;
        }
      }

      &:hover {
        background-color: $navigation-dropdown-hover;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -6px * 2;
      left: 50%;
      margin-left: -6px;
      border: 6px solid transparent;
      border-bottom-color: $navigation-dropdown-bg;

      filter: drop-shadow(0px -2px 0px $navigation-dropdown-border);
    }
  }
}
