$image-lazy-background: $body-bg;
$image-lazy-spinner-color: $gray-darker;

img:not([src*=".svg"]) {
  max-width: 100%;
  //width: auto;
  height: auto;
  @include on-ie(any) {
    width: 100%;
  }
}

.image,
figure {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  %caption {
    display: block;
    opacity: 0.8;
  }

  > figcaption {
    @extend %caption;
  }

  @at-root .image__caption {
    @extend %caption;
  }
}

.lazyImage {
  position: relative;

  .ce-textpic & img {
    //background-color: $image-lazy-background;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="spinner" viewBox="0 0 66 66"><style>.spinner { transform-origin: center; -webkit-animation: rotator 1.5s linear infinite; animation: rotator 1.5s linear infinite; } @-webkit-keyframes rotator { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 100% { -webkit-transform: rotate(270deg); transform: rotate(270deg); } } @keyframes rotator { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 100% { -webkit-transform: rotate(270deg); transform: rotate(270deg); } } .path { stroke-dasharray: 186; stroke-dashoffset: 0; -webkit-transform-origin: center; transform-origin: center; stroke: #{$image-lazy-spinner-color}; -webkit-animation: dash 1.5s ease-in-out infinite; animation: dash 1.5s ease-in-out infinite; } @-webkit-keyframes dash { 0% { stroke-dashoffset: 186; } 50% { stroke-dashoffset: 46.5; -webkit-transform: rotate(135deg); transform: rotate(135deg); } 100% { stroke-dashoffset: 186; -webkit-transform: rotate(450deg); transform: rotate(450deg); } } @keyframes dash { 0% { stroke-dashoffset: 186; } 50% { stroke-dashoffset: 46.5; -webkit-transform: rotate(135deg); transform: rotate(135deg); } 100% { stroke-dashoffset: 186; -webkit-transform: rotate(450deg); transform: rotate(450deg); } }</style><circle cx="33" cy="33" r="30" fill="none" stroke-width="6" class="path" stroke-linecap="round"/></svg>');
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__sizer {
    position: relative;
    z-index: 0;
  }

  &__image {
    position: absolute;
    z-index: 1;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;

    .lazyImage & {
      &--loaded {
        opacity: 1;
      }

      &--broken {
        opacity: 1;

        background-color: mix($brand-danger, $image-lazy-background, 15%);
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#{$brand-danger}" d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>') !important;
      }
    }
  }
}
