/*
        FONT FACES
*/

$helvetica: "Helvetica Neue", Helvetica;
$mono: Hack, Menlo, Monaco;

$font-family-sans-serif: ("Open Sans", $helvetica, sans-serif) !default;
$font-family-serif: (Georgia, "Times New Roman", Times, serif) !default;
$font-family-monospace: $mono, Consolas, monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$line-height-base: 1.5 !default;

/*
        DEFAULT FONT
*/

// Default text color
$text-color--black: #363636 !default; // on light backgrounds
$text-color--white: #fff !default; // on dark backgrounds

$text-muted: color("foreground", "muted") !default;

/*
        FONT SIZES
*/

$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-color: color("foreground", "dark");

// General
$font-size-base: 16px;
$font-size-large: ceil($font-size-base * 1.36);
$font-size-small: ceil($font-size-base * 0.8);

// Headings
$font-size-h1: 30px;
$font-size-h2: 26px;
$font-size-h3: 22px;
$font-size-h4: 20px;
$font-size-h5: 18px !default;
$font-size-h6: ceil($font-size-base * 0.85) !default;

// Headings Mobile
$font-size-h1-mobile: 26px !default;
$font-size-h2-mobile: 22px !default;
$font-size-h3-mobile: 20px !default;
$font-size-h4-mobile: 16px !default;
$font-size-h5-mobile: 16px !default;
$font-size-h6-mobile: 16px !default;
$font-size-base-mobile: 16px !default;

// Text definition
$lead-color: color("foreground", "lead");
$lead-font-size: 22px;
$lead-font-weight: 400;

// Horizontal Ruler — HR

$hr-margin-y: 25px;
$hr-border-width: 1px;
$hr-border-color: color("background", "seperator");

// Mark
$mark-padding: 0.2em !default;
$mark-bg: color-pick("mark") !default;

// Inline & Unstyled Lists
$list-inline-padding: 0.5rem !default;

// Paging
$paging-font-size: 16px;
$paging-font-size-small: 13px;
