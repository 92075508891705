
$auw-shared: (icons: (this: does totally not work in this project, go: away));
@import "config";

// import lib
@import "slick-carousel/slick/slick.scss";

.slick {
  &-slide {
    height: auto !important;

    &,
    > div {
      line-height: 0;
    }
  }
}

.slide {
  &__content {
    position: relative;
  }

  &__block {
    position: absolute;
    z-index: 10;
  }

  &__text {
    margin-top: 0;
  }
}

.slider {
  &__arrow {
    position: absolute;
    z-index: 10;

    cursor: pointer;
    transform: translate3d(0, -50%, 0);

    &--next {
      right: 0;
    }

    &--prev {
      left: 0;
    }
  }
}
