$tag-background-color: color("primary");
$tag-space-between: 3px;
$tag-padding: 0.6rem 0.8rem;
$tag-margin: 0 10px 8px 0;
$tag-font-size: 1.2rem;

.tag {
  position: relative;
  display: inline-block;

  text-transform: uppercase;
  padding: $tag-padding;
  margin: $tag-margin;
  font-size: $tag-font-size;
  letter-spacing: 1px;
  line-height: 1;

  color: text-color($tag-background-color);
  background: $tag-background-color; // this gets overridden if tag has custom color value

  font-weight: normal;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: #fff;
    text-decoration: none;
  }

  & + & {
    margin-left: $tag-space-between;
  }

  // tooltip
  &:hover::after {
    @extend .fadeIn;
    animation-duration: 300ms;

    content: attr(data-tooltip);

    display: inline-block;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 170px;

    text-align: center;
    text-transform: none;
    font-size: 16px;
    hyphens: auto;

    padding: 3px 4px;
    border-radius: $border-radius-base;
    box-shadow: 1px 1px 10px 0 rgba($gray, 0.66);

    // change following two lines if tooltip should have fixed colors
    background-color: rgba($color-black, 0.8);
    color: $color-white;
  }

  &:not([data-tooltip])::after {
    display: none !important;
  }

  &[data-tooltip=""]:hover::before,
  &[data-tooltip=""]:hover::after,
  &:active::before,
  &:active::after {
    display: none;
  }

  // parent element that contains each tag
  &__collection {
    position: relative;
    display: block;
    @include text-color($tag-background-color);
  }

  &__cloud {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
