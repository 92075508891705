$breadcrumb-divider: "\BB" !default;
$breadcrumb-diviver-spaces-between: true !default;
$breadcrumb-height: 4rem !default;
.breadcrumb {
  $root: &;

  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
  margin-top: 10px;
  
  padding: 0;
  width: 100%;
  min-height: $breadcrumb-height;
  font-size: 1.4rem;
  font-weight: 400;
  &__item {
    display: inline-block;
    &.is-active {
      color: color("blue-gray");
      cursor: default;
    }
    &:not(:last-child)::after {
      padding-left: 0.1em;
      color: color("accent");
      @if ($breadcrumb-diviver-spaces-between==true) {
        content: "\A0" + $breadcrumb-divider + "\A0";
      } @else {
        content: $breadcrumb-divider;
      }
    }
  }
}
