$button-default-padding: 18px !default;
$button-default-height: 38px !default;
$button-styles: (
  spacegray: (
    bg: color-pick("blue-gray"),
    border: transparent,
    text: $color-white
  ),
  primary: (
    bg: color("accent"),
    border: transparent,
    text: $color-white
  ),
  accent: (
    bg: transparent,
    border: currentColor,
    text: color("accent")
  )
);

%button-base {
  cursor: pointer;
  display: inline-block;
  font-size: rem(16);
  font-weight: 400;

  padding: 0 $button-default-padding 0.15em $button-default-padding;
  min-height: $button-default-height;
  line-height: $button-default-height;

  border: 1px solid transparent;

  letter-spacing: 0.05rem;
  margin-bottom: 1rem;

  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  appearance: none;
}

.btn,
[type="submit"],
[type="reset"],
[type="button"] {
  @extend %button-base;

  &:disabled {
    opacity: 0.5;
  }
}

.button {
  @extend %button-base;
  $root: &;

  &--block {
    display: block;
  }

  &--squared {
    border-radius: 0;
  }

  /* So our main buttons are also rounded */
  &--accent,
  &--primary {
    @extend #{$root}--squared;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      background-color: color("accent", "dark") !important;
    }
  }

  /* Also ::after the font-awesome caret as by the styleguide */
  &--primary {
    &::after {
      @include icon("angle-right", true);
      margin-left: 0.3em;
    }
  }

  &--accent {
    &:hover:not([disabled]),
    &:hover:not([disabled]) {
      border-color: currentColor;
    }
  }

  @each $selector, $args in $button-styles {
    @if ($selector != "default") {
      &--#{$selector} {
        @include style-button($args);
      }
    } @else {
      @include style-button($args);
    }
  }
}

.btn-primary {
  @extend .button--primary;

  transition:.1s;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: color("accent", "dark") !important;
    transition:.1s;
  }
}

.btn-secondary {
  @extend .button--accent;

  transition:.1s;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: color("accent", "dark") !important;
    color: white;

    border: 1px solid color("accent", "dark");

    transition:.1s;
  }
}

.btn-link {
  @extend %no-link;
}

.powermail_field .powermail_submit {
  &:hover {
    background: color("accent", "dark") !important;
  }
}
