:root,
html {
  // times 1 so we're having a proper pixel-value
  font-size: $document-base-size * 1px !important;

  display: flex;
  justify-content: center;
  align-items: center;

  body {
    min-height: 100vh;
    overflow-x: hidden;
  }
}

body,
#view-box-site {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: $document-max-width;

  font-family: $font-family-base;
  font-size: $font-size-base;

  background-color: $body-bg;
  color: $text-color;

  @include media ("<=phone") {
    font-size: $font-size-base-mobile;
  }

  > .area-header {
    order: -1;
  }
}

[\@global$="theme"] {
  color: $browser-color;
}

::selection {
  $selection-color: darken($body-bg, 12%);
  background: $selection-color;
  color: text-color($selection-color);
}

@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Hide framework's variables
//TODO: Remove in near future if there are no more 2017-components
var {
  display: none !important;
}
