@import "mixins/*";

////
/// Applies CSS only on desired IE Version
/// IE detection done by auw-framework
////
@mixin on-ie($ie-version: 10) {
  $current-selector: &;
  @at-root html {
    @if ($ie-version==any) {
      &:not([data-ie="false"]) #{$current-selector} {
        @content;
      }
    } @else {
      &[data-ie="#{$ie-version}"] #{$current-selector} {
        @content;
      }
    }
  }
}

////
/// Applies CSS only on desired pageId.
////
@mixin on-page($pageid) {
  @at-root body#body_#{$pageid} {
    @content;
  }
}

@mixin antialiasize() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin if-color-bright($color) {
  @if luminance($color) >.5 {
    @content;
  }
}

@mixin if-color-dark($color) {
  @if luminance($color) <=.5 {
    @content;
  }
}

@mixin text-color(
  $background,
  $white: $text-color--white,
  $black: $text-color--black
) {
  @include if-color-bright($background) {
    color: $black;
  }
  @include if-color-dark($background) {
    color: $white;
  }
  @content;
}
