$color-white: #fff;
$color-black: #363636;

$colors: (
  "primary": (
    "base": #342A94,
    "light": #1b57b0,
    "dark": #002663
  ),
  "accent": (
    "base": #F07300,
    "dark": #C94600
  ),
  "secondary": (
    "base": #5a1321,
    "light": #7b1a2d,
    "dark": #51111e
  ),
  "foreground": (
    "base": #363636,
    "light": #333333,
    "dark": #111111,
    "darker": #000000,
    // "lead": #103c83,
    "lead": #342A94,
    "muted": #8c8c8c
  ),
  "background": (
    "base": #e9e9e9,
    "light": #ffffff,
    "dark": #dddddd,
    "seperator": #d4d4d4
  ),
  "base-gray": #f3f3f4,
  "blue-gray": #999999,
  "mark": #fcf8e3,
  "blockquote": #555555
);

$gray: color-pick("base-gray");
$gray-light: tint($gray, 15%);
$gray-lighter: tint($gray, 50%);
$gray-dark: shade($gray, 15%);
$gray-darker: shade($gray, 50%);

$state-colors: (
  "success": #5cb85c,
  "success-light": #d2e6d2,
  "info": #2196f3,
  "warning": #ffc107,
  "danger": #fd0000,
  "danger-light": #eababa,
  "danger-lighter": #fbd0d0
);
