////////////////////////
////////
//////// ANIMATIONS
////////
//////// #1 Global Transition Values
//////// #2 reflectEffect
////////
////////////////////////

////////////////////////
//////// #1 Global Transition Values
// easings
$ease-custom: cubic-bezier(
  0.68,
  -0.55,
  0.265,
  1.55
); // generate custom bezier curve at https://matthewlein.com/ceaser/
$ease: ease; // use default 'ease' or '$ease-custom';
// times
$time: 300ms; // master variable
$time_short: $time * 0.5;
$time_long: $time * 2;
// transitions
$transition: $time $ease;
$transition_medium: $transition;
$transition_short: $time_short $ease;
$transition_long: $time_long $ease;

////////////////////////
//////// #2 reflectEffect
//
// by niklas brandt - last update May 2017
//
// usage:
// @include reflect-effect(':active', opacity, duration, count, zIndex);
//
////////////////////////
$reflect-effect-size: $document-max-width; //for maximum sharpness use docMaxWidth as there will be no bigger element with this effect

%reflectEffectElement::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: $reflect-effect-size;
  height: $reflect-effect-size * 2;
  top: -$reflect-effect-size * 2;
  left: 0;
  display: none;
  background: transparent;
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 1) 50%,
    hsla(0, 0%, 100%, 0) 50.0244%
  );
}
@mixin reflect-effect(
  $state,
  $opacity,
  $duration,
  $count,
  $zIndex,
  $effect-size: $reflect-effect-size
) {
  @extend %reflectEffectElement;

  &#{$state} {
    overflow: hidden;
  }
  position: relative;
  @keyframes reflectEffectAnimation {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: $opacity;
    }

    100% {
      transform: translate3d(0, $effect-size * 2, 0);
      opacity: 0;
    }
  }

  &#{$state}::after {
    z-index: $zIndex;
    display: block;
    animation: reflectEffectAnimation $duration $count;
  }
}
////////////////////////////////////////////////////////////////////////
////////
//////// CARD COMPONTENT CONFIGURATION
////////
//////// #1 TRIGGERS
//////// #2 .CARD baseclass // .CARD.CARD--SHORT modifier // .CARD.CARD--ASIDE modifier // .CARD.CARD--FLIP modifier // .CARD.CARD--BACKDROP modifier // .CARD.CARD--INVERT modifier
//////// #3 .CARD.CARD--PERSON modifier // .CARD.CARD--PERSON.CARD--SHORT modifier
//////// #4 .CARD.CARD--TAG modifier // .CARD.CARD--TAG.CARD--SHORT modifier
////////
//////// Use this to group content (title, description,
//////// image, link, persons, tags, etc). A card can
//////// be a link, but can be used to group unlinked,
//////// static content as well.
////////
//////// By using this component on many elements a cohesive
//////// and uniform user experience and design is achieved.
////////
//////// Add/remove styles on '.card' to change all elements
//////// that use this class.
//////// Don't change styles if you want to adress only a
//////// specific element that gets extended by '.card'
//////// component - use designated selector instead:
//////// '.teaser' or '.auwSearch__result'
////////
//////// You can also make new modifier classes such as:
//////// .card--flip, .card--backdrop, .card--person .card--tag
//////// and make new template in Pages Meta that uses it
////////
//////// by Niklas Brandt - last update July 26th 2017
//////// niklas.brandt@artundweise.de
////////
//////// all of the following takes effect on
//////// everything that uses '.card'-class:
//////// - .teaser by EXT/auw_pages_meta
//////// - static .auwSearch__result by EXT/auw_solr on Searchpage
//////// - slides with text (type 1 & 4)
//////// -> more? feel free to add to this list
////////
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//////// #1 TRIGGERS
$card_clickability_trigger: true;
// clickability on whole element
$card_opacity_trigger: true;
// opacity effects on card on :hover and :active
$card_sidepadding_trigger: false; // left and right padding on sections
$card_roundness_trigger: false; // borderradius on card
$card_background_trigger: false; // background effects on card on default, :hover and :active
$card_boxshadow_trigger: false; // boxshadow effects on card on default, :hover and :active
$card_transform_trigger: false; // transform effects on card on :hover and :active
$card_imgeffects_trigger: false; // filter effects (contrast and grayscale) on img on :hover

////////////////////////////////////////////////////////////////////////
//////////////// #2 .CARD baseclass // .CARD.CARD--SHORT modifier // .CARD.CARD--ASIDE modifier // .CARD.CARD--FLIP modifier // .CARD.CARD--BACKDROP modifier // .CARD.CARD--INVERT modifier
//////// global
$card_color: color("primary"); // text-colors
$cardSize: $grid-gutter-width * 0.7; // this abstract variable manipulates most margins used below. change multiplier to make card overall more compact or spacious. it orientates after grid-gutter-width for uniform spacing site wide
$card_margin: 0 0 $grid-gutter-width 0;
$card_padding: 0 0 0 0;
//////// transition
$card_transition: opacity $transition_short, box-shadow $transition_medium,
  transform $transition_medium, background-color $transition_medium,
  filter $transition_long;
//////// eye candy
$card_backgroundcolor: hsla(0, 0%, 95%, 1);
$card_shadowColor: rgb(0, 0, 0);
$card_shadow: 0 3px 2px 0 rgba($card_shadowColor, 0.1),
  0 0 0 2px rgba($card_shadowColor, 0.05);
$card_borderRadius: 3px;
//////// ZINDEX parameters
$card_zIndex_primary: 2; // used for making whole element clickable (via css-only). change this if it interferes with other z-index elements on page
$card_zIndex_secondary: $card_zIndex_primary + 1; // used for bringing inner-/sub-clickable elements to front because whole card is clickable. secondary must always be at least 1 more than primary
$card_zIndex_effect: $card_zIndex_secondary + 1; // used for bringing effect to front (has pointer-events: none; anyways)
$card_zIndex_backdrop: $card_zIndex_primary; // used for bringing inner-/sub-clickable elements to front because whole card is clickable. secondary must always be at least 2 less than primary (see --backdrop why)
//////// &:hover
$card_hover_transition: opacity $transition_short, box-shadow $transition_medium,
  transform $transition_medium, background-color $transition_medium,
  filter $transition_long;
$card_hover_background: darken($card_backgroundcolor, 4%);
$card_hover_opacity: 0.85;
$card_hover_shadow: 0 3px 3px 0 rgba($card_shadowColor, 0.2),
  0 0 0 2px rgba($card_shadowColor, 0.1);
$card_hover_transform: scale3d(1, 1, 1);
$card_hover_imgcontrast: 130%; //	Adjusts the contrast of the image. 0% will make the image completely black. 100% (1) is default and represents the original image. Values over 100% will provide results with less contrast.
$card_hover_imggrayscale: 10%; // 0-100% // Converts the image to grayscale. 0% (0) is default and represents the original image. 100% will make the image completely gray (used for black and white images).
//////// &:active
$card_active_transition: opacity $transition_short, box-shadow $transition_short,
  transform $transition_short, background-color $transition_short,
  filter $transition_long;
$card_active_background: darken($card_backgroundcolor, 8%);
$card_active_opacity: 0.6;
$card_active_shadow: 0 2px 2px 0 rgba($card_shadowColor, 0.08),
  0 0 0 1px rgba($card_shadowColor, 0.04);
$card_active_transform: scale3d(0.991, 0.991, 0.991);
//////// sections
$card_header_padding: 0 0 15px 0;
$card_body_padding: 0 $cardSize;
$card_footer_padding: 0 $cardSize;
//////// tag
$card_tag_margin: 0 $cardSize/3 0 0;
$card_tagContainer_margin: $cardSize/3 $cardSize/4 $cardSize/3 0;
//////// date
$card_date_margin: 0 0 $cardSize/3 0;
//////// title
$card_title_color: $card_color;
$card_title_margin: $cardSize/4 0 $cardSize/3 0;
//////// description
$card_description_margin: $cardSize/3 0 $cardSize/4 0;
//////// horizontal line hr
$card_hr_color: lighten(color("base-gray"), 66%);
$card_hr_margin: 0 0 $cardSize/2 0;

//////////////// .CARD--SHORT modifier
$card_short_textAlign: left; //left, right or center
$card_short_header_padding: 0 0 0 0;
$card_short_body_padding: 0 0 0 $cardSize;

//////////////// .CARD--ASIDE modifier
$card_aside_imgWidth: 220px; //defines width on image for aside configuration (for example searchresults)

//////////////// .CARD--FLIP modifier
$card_flip_back_padding: 12px 25px;
$card_flip_perspective: 900px; // changes perspective for transform of flipping animation
$card_flip_transition: transform $transition_long; // flip transform transition parameters

//////////////// .CARD--BACKDROP modifier
$card_backdrop_padding: 20px 25px;
$card_backdrop_gradient_color: rgb(0, 0, 0);
$card_backdrop_gradient_opacity: 0.75;
$card_backdrop_gradient_opacity_hover: 0.95;
$card_backdrop_transition: opacity $transition_medium;
$card_backdrop_minHeight: 250px;
$card_backdrop_hover_fill: hsla(0, 0%, 0%, 0.5);

//////////////// .CARD--INVERT modifier
$card_invert_color: #fff;

////////////////////////////////////////////////////////////////////////
//////////////// #3 .CARD.CARD--PERSON modifier // .CARD.CARD--PERSON.CARD--SHORT modifier
//////// image
$cardPersonType_image: 220px;
$cardPersonType_image_roundness: 100%;
$cardPersonType_image_margin: 0 $cardSize 0 0;
//////// sections
$cardPersonType_header_minWidth: 0; //used for detail block
$cardPersonType_body_minWidth: 30%; //used for detail block
$cardPersonType_footer_minWidth: 0; //used for detail block
$cardPersonType_header_padding: $cardSize $cardSize $cardSize $cardSize;
$cardPersonType_body_padding: $cardSize 0 $cardSize 0;
$cardPersonType_footer_padding: 0;
//////// persons position
$cardPersonType_position_margin: $cardSize * 0.2 0 0 0;
//////// data
$cardPersonType_data_margin: 0 0 5px 0;

//////////////// .CARD--PERSON.CARD--SHORT modifier
//////// sections
$cardPersonType_short_header_padding: 0; //$cardSize $cardSize 0 $cardSize;
$cardPersonType_short_body_padding: 0; //$cardSize;
//////// image
$cardPersonType_short_image: 85px;
$cardPersonType_short_image_roundness: 100%;
//////// position
$cardPersonType_short_name_position_size: 100%;

////////////////////////////////////////////////////////////////////////
//////////////// #4 .CARD.CARD--TAG modifier // .CARD.CARD--TAG.CARD--SHORT modifier
//////// sections
$cardTagType_body_padding: 0;
//////// tag
$cardTagType_tag_fontSize: 150%;
$cardTagType_tag_padding: $cardSize/2 $cardSize/1.5;
$cardTagType_tag_margin: 0;
//////// description
$cardTagType_description_margin: $cardSize/2 0 $cardSize/3 0;

//////////////// .CARD--TAG.CARD--SHORT modifier
//////// sections
$cardTagType_short_body_padding: 0;
//////// tag
$cardTagType_short_tag_fontSize: 12px;
$cardTagType_short_tag_padding: 5px 7px;
$cardTagType_short_tag_margin: 0 10px 8px 0;

////////////////////////////////////////////////////////////////////////
////////
//////// CARD COMPONTENT
////////
//////// CONFIGURATION TO THIS FILE IS LOCATED UNDER:
//////// utils/variables/_card.scss
////////
//////// #1 MIXINS
//////// #2 .CARD baseclass // .CARD.CARD--SHORT modifier // .CARD.CARD--ASIDE modifier // .CARD.CARD--FLIP modifier // .CARD.CARD--BACKDROP modifier // .CARD.CARD--MINIMAL modifier // .CARD.CARD--INVERT modifier
//////// #3 .CARD.CARD--PERSON // .CARD.CARD--PERSON.CARD--SHORT // .CARD.CARD--PERSON.CARD--DETAIL
//////// #4 .CARD.CARD--TAG // .CARD.CARD--TAG.CARD--SHORT
////////
//////// Use this to group content (title, description,
//////// image, link, persons, tags, etc). A card can
//////// be a link, but can be used to group unlinked,
//////// static content as well.
////////
//////// By using this component on many elements a cohesive
//////// and uniform user experience and design is achieved.
////////
//////// Add/remove styles on '.card' to change all elements
//////// that use this class.
//////// Don't change styles if you want to adress only a
//////// specific element that gets extended by '.card'
//////// component - use designated selector instead:
//////// '.teaser' or '.auwSearch__result'
////////
//////// You can also make new modifier classes such as:
//////// .card--flip, .card--backdrop, .card--person .card--tag
//////// and make new template in Pages Meta that uses it
////////
//////// by Niklas Brandt - last update September 19 2017
//////// niklas.brandt@artundweise.de
////////
//////// all of the following takes effect on
//////// everything that uses '.card'-class:
//////// - .teaser by EXT/auw_pages_meta
//////// - static .auwSearch__result by EXT/auw_solr on Searchpage
//////// - slides with text (type 1 & 4)
//////// -> more? feel free to add to this list
////////
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//////////////// #1 MIXINS

// this removes base styles and sets values to none or transparent. Use this mixin to build elements that use card mechanics but don't feature basic card look
@mixin remove-basestyles($root: ".card") {
  background-color: transparent;
  border-radius: none;
  box-shadow: none;
  transition: none;
  overflow: visible;

  &:hover {
    transition: none;
    background-color: transparent;
    opacity: 1;
    box-shadow: none;
    transform: none;

    #{$root}__image img {
      transition: none;
      filter: none;
      height: 100%;
    }
  }

  &:active {
    transition: none;
    background-color: transparent;
    opacity: 1;
    box-shadow: none;
    transform: none;
  }
}
// use this mixin to brighten text for dark backgrounds
@mixin invert-text {
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: $card_invert_color;

    &:hover {
      color: $card_invert_color;
    }
  }
}
// mixin for triggering backgroundcolor
@mixin card-backgroundcolor($trigger, $value) {
  @if $trigger == true {
    background-color: $value;
  } @else {
    background-color: transparent;
  }
}
// mixin for triggering border-radius
@mixin card-roundness($trigger, $value) {
  @if $trigger == true {
    border-radius: $value;
  } @else {
    border-radius: 0;
  }
}
// mixin for triggering box shadow
@mixin card-boxshadow($trigger, $value) {
  @if $trigger == true {
    box-shadow: $value;
  } @else {
    box-shadow: none;
  }
}
// mixin for triggering reset of sidepaddings
@mixin card-sidepadding($trigger) {
  @if $trigger != true {
    padding-left: 0;
    padding-right: 0;
  }
}
// mixin for triggering opacity
@mixin card-opacity($trigger, $value: 1) {
  @if $trigger == true {
    opacity: $value;
  } @else if $trigger == false {
    opacity: 0.99999;
  }
}
// mixin for triggering transformation
@mixin card-transform($trigger, $value) {
  @if $trigger == true {
    transform: $value;
  }
}
// mixin for triggering image effects
@mixin card-imgeffects($trigger, $contrast, $grayscale, $root: ".card") {
  @if $trigger == true {
    #{$root}__image img {
      transition: $card_hover_transition;
      filter: contrast($contrast) grayscale($grayscale);
    }
  }
}
//mixin for triggering full clickability
@mixin card-clickability($trigger, $target) {
  @if $trigger == true {
    @if $target == "primary" {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $card_zIndex_primary;
      }
    } @else if $target == "secondary" {
      z-index: $card_zIndex_secondary;
    }
  }
}

////////////////////////////////////////////////////////////////////////
//////////////// #2 .CARD baseclass // .CARD.CARD--SHORT modifier // .CARD.CARD--ASIDE modifier // .CARD.CARD--FLIP modifier // .CARD.CARD--BACKDROP modifier // .CARD.CARD--MINIMAL modifier // .CARD.CARD--INVERT modifier
.card {
  $root: &;

  position: relative;
  padding: $card_padding;
  margin: $card_margin;
  transition: $card_transition;
  overflow: hidden;
  @include card-backgroundcolor(
    $card_background_trigger,
    $card_backgroundcolor
  );
  @include card-roundness($card_roundness_trigger, $card_borderRadius);
  @include card-boxshadow($card_boxshadow_trigger, $card_shadow);
  @include card-sidepadding($card_sidepadding_trigger);

  &:hover {
    transition: $card_hover_transition;
    overflow: visible;
    z-index: 1;
    @include card-backgroundcolor(
      $card_background_trigger,
      $card_hover_background
    );
    @include card-opacity($card_opacity_trigger, $card_hover_opacity);
    @include card-boxshadow($card_boxshadow_trigger, $card_hover_shadow);
    @include card-transform($card_transform_trigger, $card_hover_transform);
    @include card-imgeffects(
      $card_imgeffects_trigger,
      $card_hover_imgcontrast,
      $card_hover_imggrayscale
    );
  }

  &:active {
    transition: $card_active_transition;
    @include card-backgroundcolor(
      $card_background_trigger,
      $card_active_background
    );
    @include card-opacity($card_opacity_trigger, $card_active_opacity);
    @include card-boxshadow($card_boxshadow_trigger, $card_active_shadow);
    @include card-transform($card_transform_trigger, $card_active_transform);
  }

  &__header {
    padding: $card_header_padding;
    @include card-sidepadding($card_sidepadding_trigger);
  }

  &__body {
    padding: $card_body_padding;
    height: auto;
    @include card-sidepadding($card_sidepadding_trigger);
  }

  &__footer {
    padding: $card_footer_padding;
    @include card-sidepadding($card_sidepadding_trigger);
  }

  #{$root}__image {
    display: block;

    img {
      transition: $card_transition;

      &:hover {
        transition: $card_hover_transition;
      }

      &:active {
        transition: $card_active_transition;
      }
    }
  }

  #{$root}__tagContainer {
    margin: $card_tagContainer_margin;

    #{$root}__tag {
      margin: $card_tag_margin;
      display: inline-block;
      word-break: break-word;
    }
  }

  #{$root}__date {
    margin: $card_date_margin;
    font-size: 14px;
  }

  a {
    @include card-clickability($card_clickability_trigger, "secondary");
    position: relative;
  }

  p {
    word-wrap: break-word;
  }

  hr {
    background: $card_hr_color;
    border-color: transparent;
    margin: $card_hr_margin;
  }

  &__title {
    margin: $card_title_margin;
    display: block;
    word-wrap: break-word;
    color: $card_title_color;
    text-decoration: none;

    a {
      text-decoration: none;
      position: inherit;
      @include card-clickability($card_clickability_trigger, "primary");
      //
      // &:hover {
      //   @include card-opacity($card_opacity_trigger, $card_hover_opacity);
      // }
      // &:active {
      //   @include card-opacity($card_opacity_trigger, $card_active_opacity);
      // }
    }
  }

  //////////////// .CARD--SHORT modifier
  &--short {
    text-align: $card_short_textAlign;

    #{$root}__header {
      padding: $card_short_header_padding;
    }

    #{$root}__body {
      padding: $card_short_body_padding;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  //////////////// .CARD--ASIDE modifier
  &--aside {
    display: flex;

    @include media("<=phone") {
      flex-direction: column;
    }

    #{$root}__header {
      figure {
        max-width: $card_aside_imgWidth;

        @include media("<=phone") {
          max-width: 100%;
        }
      }
    }

    #{$root}__body {
      @include media("<=phone") {
        padding: 10px 0 0;
      }
      flex: 1;
    }
  }

  //////////////// .CARD--FLIP modifier
  /*
  use in combination with card--flip markup:
  .card.card--flip
    .card__sideWrap
      .card__back
        .card
          .card__body
          .card__footer
      .card__front
        .card
          .card__header
  make sure front image provided is high enough to not cut back content off.
  also test different screensizes and content scenarios
  */
  &--flip {
    perspective: $card_flip_perspective;

    // because we have basically 1 card containing two other cards (front and back) we don't need basic styles on wrapper/outer card
    @include remove-basestyles();

    &:hover {
      #{$root}__sideWrap {
        transform: rotateY(180deg);
      }
    }

    #{$root} {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
    }

    #{$root}__sideWrap {
      position: relative;
      transform-style: preserve-3d;
      transition: $card_flip_transition;
    }

    #{$root}__back,
    #{$root}__front {
      backface-visibility: hidden;
    }

    #{$root}__front {
      transform: rotateY(0);
      cursor: pointer;

      #{$root}__header {
        padding: 0;
      }
    }

    #{$root}__back {
      transform: rotateY(180deg);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      #{$root}__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
      }

      #{$root} {
        padding: $card_flip_back_padding;
        overflow: hidden;
      }

      #{$root}__body,
      #{$root}__footer {
        width: 100%;
      }
    }

    // backface visibility fix and flexbox image resize fix for bloody IE11
    @include on-ie(any) {
      &:hover {
        #{$root}__front {
          visibility: hidden;
        }

        #{$root}__back {
          visibility: visible;
        }
      }

      #{$root}__sideWrap {
        transition-duration: 200ms;
      }

      #{$root}__back,
      #{$root}__front {
        backface-visibility: visible;
      }

      #{$root}__front {
        position: relative;

        #{$root}__header {
          min-height: 1px;
        }
      }

      #{$root}__back {
        visibility: hidden;
      }
    }
  }

  //////////////// .CARD--BACKDROP modifier
  &--backdrop {
    @include invert-text;

    &:active,
    &:hover {
      @include card-opacity(false);
    }

    #{$root}__body {
      padding: $card_backdrop_padding;
      min-height: $card_backdrop_minHeight;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      flex-wrap: wrap;
      background-size: cover;
      background-position: center center;

      &:hover {
        &::before {
          opacity: 1;
        }
      }
      #{$root}__body {
        z-index: $card_zIndex_backdrop + 1;
      }
      // bring everything on top of gradient
      // use: p,h1,h2,h3,h4,h5,h6 {  or:
      *:not(.tag__collection):not(.flex) {
        z-index: $card_zIndex_backdrop + 1;
      }
      // bring links on top of everything
      a {
        z-index: $card_zIndex_secondary !important;
      }
      // bring title on top of everything
      #{$root}__title {
        z-index: $card_zIndex_primary;
        color: $color-white;

        a {
          z-index: $card_zIndex_primary + 1 !important;
        }
      }

      #{$root}__tagContainer {
        z-index: 10;
      }
    }
  }

  //////////////// .CARD--MINIMAL modifier
  &--minimal {
    @include remove-basestyles;
  }

  //////////////// .CARD--INVERT modifier
  &--invert {
    @include invert-text;
  }

  ////////////////////////////////////////////////////////////////////////
  //////////////// #3 .CARD.CARD--PERSON // .CARD.CARD--PERSON.CARD--SHORT // .CARD.CARD--PERSON.CARD--DETAIL
  &--person {
    #{$root}__header {
      min-width: $cardPersonType_header_minWidth;
      padding: $cardPersonType_header_padding;
      text-align: center;
    }

    #{$root}__body {
      min-width: $cardPersonType_body_minWidth;
      padding: $cardPersonType_body_padding;

      @include media("<=tablet") {
        padding-top: 0;
        display: block !important;
      }
    }

    #{$root}__footer {
      min-width: $cardPersonType_footer_minWidth;
      padding: $cardPersonType_footer_padding;
      text-align: left;
    }

    #{$root}__body,
    #{$root}__footer,
    #{$root}__header {
      display: inline-block;
      vertical-align: top;
    }

    #{$root}__image {
      display: block;
      margin: $cardPersonType_image_margin;

      img {
        border-radius: $cardPersonType_image_roundness;
      }
    }

    #{$root}__position {
      margin: $cardPersonType_position_margin;
    }

    #{$root}__data {
      margin: $cardPersonType_data_margin;
      @include card-clickability($card_clickability_trigger, "secondary");
      position: relative;
    }
  }
  //////////////// .CARD--SHORT modifier
  &--person#{$root}--short {
    vertical-align: center;

    #{$root}__header {
      display: block;
      text-align: center;
      padding: $cardPersonType_short_header_padding;
    }

    #{$root}__body {
      padding: $cardPersonType_short_body_padding;
      display: flex;

      #{$root}__position,
      #{$root}__title {
        margin: 0;
      }

      #{$root}__position {
        line-height: 1.1;
      }
    }

    #{$root}__body,
    #{$root}__header {
      @if $card_sidepadding_trigger != true {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__image {
      margin: 0 10px 0 0;
      max-width: $cardPersonType_short_image;

      img {
        border-radius: $cardPersonType_short_image_roundness;
      }
    }

    #{$root}__position {
      font-size: $cardPersonType_short_name_position_size;
    }
  }
  //////////////// .CARD--DETAIL modifier
  &--person#{$root}--detail {
    #{$root}__image {
      max-width: $cardPersonType_image;
    }
  }

  ////////////////////////////////////////////////////////////////////////
  //////////////// #4 .CARD.CARD--TAG // .CARD.CARD--TAG.CARD--SHORT
  &--tag {
    text-align: left;

    #{$root}__body {
      padding: $cardTagType_body_padding;
      @if $card_sidepadding_trigger != true {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__tag {
      font-size: $cardTagType_tag_fontSize;
      padding: $cardTagType_tag_padding;
      margin: $cardTagType_tag_margin;
      text-decoration: none;
      position: inherit;
      word-break: break-word;
      display: table;

      &:hover::after {
        display: none; //prevent unnecessary tooltip
      }
    }

    #{$root}__description {
      margin: $cardTagType_description_margin;
    }
  }
  //////////////// .CARD--SHORT modifier
  &--tag#{$root}--short {
    text-align: left;
    margin: 0;

    #{$root}__body {
      padding: $cardTagType_short_body_padding;
      @if $card_sidepadding_trigger != true {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__tag {
      font-size: $cardTagType_short_tag_fontSize;
      padding: $cardTagType_short_tag_padding;
      margin: $cardTagType_short_tag_margin;
    }
  }
}
