$fa-font-size-base: 14px;
$fa-line-height-base: 1;
$fa-css-prefix: fa;
$fa-border-color: #eee;
$fa-inverse: #fff;
$fa-li-width: (30em / 14);
$fa-version: "4.7.0";
$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/#{$fa-version}/fonts";

@mixin icon($icon, $no-pseudo: false, $size: default) {
  @extend .fa;
  @if ($no-pseudo) {
    @extend .fa-#{$icon}:before !optional;
  } @else {
    @extend .fa-#{$icon} !optional;
  }

  @if ($size != default) {
    @extend .fa-#{$size} !optional;
  }
}

@import "font-awesome/scss/font-awesome";
