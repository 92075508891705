.accortabs {
  $root: &;
  $accortabs-background-active: #f1f1f1 !default !global;
  $accortabs-border: #ddd !default !global;
  $accortabs-content-bg: $body-bg !default !global;

  $accordion-background: #f1f1f1 !default !global;
  $tab-text-color: color("primary", "dark") !default !global;

  position: relative;

  display: flex;
  flex-direction: column;

  &--accordion {
    #{$root} {
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 0.3333em;
        @include border-radius("accordion");

        background: $accordion-background;

        &:hover {
          background-color: tint(color('accent'), 25%);
        }


        &::after {
          content: "";
          border: 6px solid transparent;

          border-right: none;
          border-left-color: currentColor;

          transition: none ease 240ms;
          transition-property: transform;
        }

        @include state(active) {
          &::after {
            transform: rotateZ(90deg);
          }
        }

        @include media('<=tablet') {
          flex-flow: row wrap;
          flex-direction: column;
          align-self: flex-start;

          > .grow {
            margin-top: 0 !important;
          }

          &::after {
            align-self: flex-end;
          }
        }
      }
    }
  }

  &--tabs {
    #{$root} {
      &__tabs {
        display: flex;
        flex-flow: row;

        white-space: nowrap;
        overflow-x: auto;

        border-bottom: 1px solid $accortabs-border;

        > :not(:last-child) {
          margin-right: 2px;
        }
      }

      &__title {
        line-height: rem(41);

        @include border-radius("tabs");
        border-bottom: none;

        color: $tab-text-color;
      }
    }
  }

  &__title {
    padding: 0 $grid-gutter-half;

    font-size: rem(16);
    line-height: rem(43);

    border: 1px solid $accortabs-border;
    color: $text-color;

    cursor: pointer;
    user-select: none;

    @include state(active) {
      background-color: $accortabs-background-active;
    }
  }

  &__section {
    position: relative;

    background-color: $accortabs-content-bg;

    height: 0;
    overflow: hidden;

    will-change: height;

    transition: none ease-in-out 300ms;
    transition-property: height, margin-top, margin-bottom, padding-top, padding-bottom;

    > .auw-ce {
      position: absolute;
      left: 0;
      right: 0;

      padding: $grid-gutter-half;
    }
  }
}


@include media ("<=tablet") {
  .accortabs.accortabs--accordion .accortabs__title {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.ce-headline- {
  margin-bottom: 10px;
}


@include media("<=phone") {

  .accortabs--accordion {
    .accortabs__title::after {
      position: absolute !important;
      right: 15px !important;
    }
  }
}
