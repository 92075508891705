$pswp__show-hide-transition-duration: 333ms;
$pswp__controls-transition-duration: 333ms;
$pswp__background-color: #000;
$pswp__placeholder-color: #222;
$pswp__box-sizing-border-box: true; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: 1500;
$pswp__assets-path: "~T3/auw_config/Resources/PubStatic/Images/"; // path to skin assets folder (preloader, PNG and SVG sprite)
$pswp__error-text-color: #ccc; // "Image not loaded" text color
$pswp__include-minimal-style: true;

@import "photoswipe/src/css/main.scss";
@import "photoswipe/src/css/default-skin/default-skin.scss";

@mixin masonry($perRow, $gap: 0) {
  display: flex;
  flex: 1 auto;
  flex-flow: row wrap;

  > * {
    width: percentage(1 / $perRow);

    @if ($gap) {
      img {
        padding: $gap / 2;
      }
    }
  }
}

.pswp {
  &__button {
    border: none;
    &:hover {
      background-color: inherit;
      color: $color-white;
    }
  }
  .imageItem {
    min-height: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__content {
      background-color: $color-white;

      .embed-responsive {
        width: 80vw;
        max-width: 1280px;
      }
    }

    &__footer {
      padding: 5px 10px;
    }

    &__copyright {
      opacity: 0.7;
    }
  }
}

.gallery {
  $root: &;

  position: relative;

  &__btn-fullscreen {
    @include style-button(
      (
        bg: $color-white,
        border: transparent,
        text: gray
      )
    );

    display: inline-block !important;
    position: absolute;

    padding: 4px;
    border-radius: 3px;

    top: 10px;
    right: 10px;
    z-index: 100;

    cursor: pointer;

    &,
    .fa::before {
      width: 30px;
      height: 30px;
      line-height: (30px - 8px);
      font-size: 18px;
      text-align: center;
    }
  }

  &__theatre {
    margin-bottom: $grid-gutter-half;
  }

  &__thumbnail {
    cursor: pointer;

    &.slick-slide {
      margin: 0 1px;
    }
  }

  #{$root}--thumbs & {
    &__thumbs {
      @include masonry(6, 2px);
      @include media("<=tablet") {
        @include masonry(4, 4px);
      }
      @include media("<=phone") {
        @include masonry(2, 5px);
      }
    }
  }

  #{$root}--full & {
    &__thumbs:not(.gallery__thumb--carousel) {
      @include masonry(4, 2px);
      > #{$root}__thumbnail {
      }
    }

    &__thumb--carousel {
      display: flex;

      .slick-list {
        flex: 1;
      }

      .slick-arrow {
        position: relative;
        width: 30px;

        cursor: pointer;

        &[class*="left"]::before {
          left: 0;
        }

        &[class*="right"]::before {
          right: 0;
        }

        &::before {
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);

          font-size: 35px;
          color: color("primary");
        }
      }
    }
  }
}
