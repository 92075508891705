$table-border-color: color("background", "seperator");
$table-row-background: shade($color-white, 4%);
$table-row-hover-background: shade($color-white, 8%);

// ----------------------------------------
// // ------ table settings ------
// ----------------------------------------

$table-border: 1px solid;
$table-margin-bottom: 25px;
$table-cell-padding: 0.6em 1em;
$table-cell-text-align: left;
$table-header-font-weight: 500;
$table-cell-border-top: $table-border $table-border-color;
$table-body-border-top: $table-border $table-border-color;
$table-bordered-border: $table-border $table-border-color;
$table-border-left: $table-border $table-border-color;
$table-bordered-border-radius: 3px;
$table-bordered-child-radius: 3px;

/*  Removes default browser settings
 *  and evens out inconsistencies. */
table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/*  Sets table width to 100%.
 *  Adds bottom-margin. */
%table {
  width: 100%;
  margin-bottom: $table-margin-bottom;
  font-size: 1em;
}

/*  Adds table cell padding,
 *  aligns text to the left,
 *  and adds a top border to each cell. */
%table th,
%table td {
  padding: $table-cell-padding;
  text-align: $table-cell-text-align;
  border-top: $table-cell-border-top;
}

/*  Sets table header font-weight to bold.
 *  Aligns table header text to the bottom of each cell. */
%table th {
  font-weight: $table-header-font-weight;
  vertical-align: bottom;
}

/*  Aligns cell text to the top of each cell. */
%table td {
  vertical-align: top;
}

/*  Removes the border-top from the first row. */
%table thead:first-child tr th,
%table thead:first-child tr td {
  border-top: 0;
}

/*  Adds border-top between two table-body sections. */
%table tbody + tbody {
  border-top: $table-body-border-top;
}

/* Adds borders and border-radius. */
%table {
  border: $table-bordered-border;
  border-collapse: separate;
  *border-collapse: collapsed;
  border-radius: $table-bordered-border-radius;
}

%table th + th,
%table td + td,
%table th + td,
%table td + th {
  border-left: $table-border-left;
}

%table thead:first-child tr:first-child th,
%table tbody:first-child tr:first-child th,
%table tbody:first-child tr:first-child td {
  border-top: 0;
}

%table thead tr:nth-child(odd) th,
%table tbody tr:nth-child(odd) th,
%table tbody tr:nth-child(odd) td {
  background-color: $table-row-background;
}

%table thead tr:hover th,
%table tbody tr:hover th,
%table tbody tr:hover td {
  background-color: $table-row-hover-background;
}

%table thead:first-child tr:first-child th:first-child,
%table tbody:first-child tr:first-child td:first-child {
  border-radius: $table-bordered-child-radius 0 0 0;
}

%table thead:first-child tr:first-child th:last-child,
%table tbody:first-child tr:first-child td:last-child {
  border-radius: 0 $table-bordered-child-radius 0 0;
}

%table thead:last-child tr:last-child th:first-child,
%table tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 $table-bordered-child-radius;
}

%table thead:last-child tr:last-child th:last-child,
%table tbody:last-child tr:last-child td:last-child {
  border-radius: 0 0 $table-bordered-child-radius 0;
}

table,
.ce-table {
  @extend %table;
}
