// Used as container-replacement when the container should
// fill the full page-width.

// give every content-element (except those who are containers)
// padding so they have an spacing of each other
/*.page-content .auw-ce:not(.container):not(.first-ce) {
  padding-top: $ce-gap;

  // uncomment to see module spacing
  //box-shadow: inset 0 1px 0 red, inset 0 0 0 1px navy, inset 0 ($ce-gap - 1px) 0 $content_background, inset 0 $ce-gap 0 navy
}*/

/// Private internal mixin helper, do-not-use!
/// @group System
@mixin _auwSpacingHelper($state, $selector, $property, $factor) {
  @if $state == default {
    &#{unquote($selector)} {
      #{unquote($property)}: $factor;
    }
  } @else {
    @include media("<=#{$state}") {
      &#{unquote($selector)} {
        #{unquote($property)}: $factor;
      }
    }
  }
}

.auw-ce {
  // --- --- ---
  // Vertical Gutter sizes
  @each $key, $section in $vertical-element-spacing {
    @for $i from 1 through (length($section)) {
      $current: nth($section, $i);
      @include _auwSpacingHelper(
        $key,
        "__sb#{$i - 1}",
        "margin-bottom",
        $current
      );
      @include _auwSpacingHelper($key, "__st#{$i - 1}", "margin-top", $current);
    }
  }

  // --- --- ---
  // Gutter fix
  &__g {
    // normal
    > .row {
      @extend .row;

      > [class*="col-"] {
        padding-left: $grid-gutter-half;
        padding-right: $grid-gutter-half;
      }
    }

    // no gutter
    &-none {
      > .row {
        margin-left: 0;
        margin-right: 0;

        > [class*="col-"] {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  /*
      FULL WIDTH CONTAINERS
    */
  &__full-width {
    $full-width: &;

    max-width: none;
    width: 100%;

    // Fixes "container inside containers, except full-width's"
    @at-root {
      .container,
      .container-fluid {
        &:not(#{$full-width}) & {
          padding: 0;
        }
      }
    }

    > .row > .col-md-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  /*
        EQUAL HEIGHT
      */
  &__equal {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;

    > [class*="col-"] {
      width: 100%;
    }
    @include media(">=tablet") {
      @for $i from 1 through $grid-columns {
        .col-md-#{$i} {
          width: ($i / 12) * 100%;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
